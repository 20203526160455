import { useReport } from './hooks/use-context';

export function InitialInfos() {
  const { questions } = useReport();
  const {
    observer_name,
    observer_registration,
    observation_date,
    observation_time,
    observation_sector,
    observation_selection,
    observation_activity
  } = questions;

  const containerStyle = {
    display: 'grid',
    gridTemplateColumns: '1fr',
    width: '100%',
    fontFamily: 'Arial, sans-serif',
    border: '1px solid black',
    marginBottom: '10px',
  };

  const headerStyle: any = {
    backgroundColor: '#d0e8b5',
    fontWeight: 'bold',
    padding: '6px',
    fontSize: '14px',
  };

  const cellStyle: any = {
    padding: '4px',
    textAlign: 'left',
    fontSize: '12px',
  };

  const importantStyle: any = {
    color: 'red',
    fontWeight: 'bold',
    transform: 'rotate(-90deg)',
    paddingTop: 70,
    textAlign: 'center',
    whiteSpace: 'nowrap',
  };

  return (
    <div style={containerStyle}>
      <div style={headerStyle}>Informações do Observador</div>
      <div style={{ display: 'grid', gridTemplateColumns: '2fr 2fr' }}>
        <div style={cellStyle}>Nome: {observer_name.answers?.[0].content}</div>
        <div style={cellStyle}>
          Cadastro: {observer_registration.answers?.[0].content}
        </div>
      </div>

      <div style={headerStyle}>Informações da Observação</div>
      <div style={{ display: 'grid', gridTemplateColumns: 'repeat(4, 1fr)' }}>
        <div style={cellStyle}>
          Data: {observation_date.answers?.[0].content}
        </div>
        <div style={cellStyle}>
          Hora: {observation_time.answers?.[0].content}
        </div>
        <div style={cellStyle}>
          Setor: {observation_sector.answers?.[0].content}
        </div>
        <div style={cellStyle}>
          ({observation_selection.answers?.[0].option === 'Goiasa' ? 'X' : ' '}
          ) Goiasa ({observation_selection.answers?.[0].option !== 'Goiasa' ? 'X' : ' '}) Terceiro
        </div>
      </div>

      <div style={cellStyle}>Atividade: {observation_activity.answers?.[0].content}</div>

      <div style={{ display: 'grid', gridTemplateColumns: '1fr 6fr' }}>
        <div style={importantStyle}>IMPORTANTE</div>
        <div style={{ padding: '6px', fontSize: '12px', lineHeight: '1.5' }}>
          ✅ Para iniciar a Observação, não é necessário informar o(s)
          colaborador(es);
          <br />
          ✅ Registrar Observações que tenha preocupação na atividade observada;
          <br />
          ✅ Após concluída a Observação e registros, inicie a conversa com o
          colaborador elogiando seus comportamentos seguros demonstrados e, se o
          quiser, os tantos comportamentos são importantes tanto para sua vida
          pessoal como para a prevenção de acidentes;
          <br />
          ✅ Em seguida, informe sua maior preocupação observada e avalie junto
          com o colaborador a causa do desvio, bem como o nível do risco;
          <br />✅ Analise junto com o colaborador proposta de ações para
          eliminar a causa do desvio (barreira).
        </div>
      </div>
    </div>
  );
}
