import { useReport } from './hooks/use-context';

export function Table() {
  const { body, questions, table_questions } = useReport();
  const { evaluator, issuer_signature } = questions;

  const containerStyle = {
    display: 'grid',
    gridTemplateColumns: '5fr 1fr 1fr 1fr 1fr',
    borderRight: '1px solid black',
    width: '100%',
    fontFamily: 'Arial, sans-serif',
  };

  const headerStyle: any = {
    backgroundColor: '#f4a582',
    fontWeight: 'bold',
    padding: '4px',
    border: '1px solid black',
    borderRight: 'none',
    textAlign: 'center',
    fontSize: '12px',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  };

  const cellStyle: any = {
    padding: '4px',
    border: '1px solid black',
    borderTop: 'none',
    borderRight: 'none',
    textAlign: 'left',
    fontSize: '12px',
  };

  const centeredCellStyle: any = {
    padding: '4px',
    border: '1px solid black',
    borderTop: 'none',
    borderRight: 'none',
    fontSize: '12px',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  };

  return (
    <div
      style={{
        display: 'grid',
        gridTemplateColumns: '1fr',
      }}
    >
      <div style={{ ...containerStyle, borderBottom: 'none' }}>
        <div style={headerStyle}>
          Em caso de desvios em algum item descrever no campo observações
        </div>
        <div style={headerStyle}>CONFORME</div>
        <div style={headerStyle}>NÃO CONFORME</div>
        <div style={headerStyle}>NÃO SE APLICA</div>
        <div style={headerStyle}>PESO</div>

        {console.log('table_questions:', table_questions)}

        {table_questions?.map((question) => (
          <>
            <div style={cellStyle}>{question.name}</div>
            <div style={centeredCellStyle}>
              {question?.answers?.[0].option === 'Conforme' ? 'X' : ''}
            </div>
            <div style={centeredCellStyle}>
              {question?.answers?.[0].option === 'Não Conforme' ? 'X' : ''}
            </div>
            <div style={centeredCellStyle}>
              {question?.answers?.[0].option === 'Não se aplica' ? 'X' : ''}
            </div>
            <div style={centeredCellStyle}>{question.weight}</div>
          </>
        ))}
      </div>
      <div style={containerStyle}>
        <div>
          <div style={{ ...cellStyle, display: 'flex' }}>
            Avaliador:
            <div style={{ marginLeft: '15px' }}>
              {evaluator?.answers?.[0]?.content}
            </div>
          </div>
          <div style={{ ...cellStyle, display: 'flex' }}>
            Assinatura do emissor:
            <img
              style={{
                objectFit: 'contain',
                width: '100%',
                height: '100%',
                maxHeight: '100px',
              }}
              src={`${issuer_signature?.answers?.[0]?.content || ''}`}
              alt="img"
            />
          </div>
        </div>
        <div
          style={{
            ...centeredCellStyle,
            gridColumn: 'span 3',
          }}
        >
          PONTUAÇÃO
        </div>
        <div
          style={{
            ...centeredCellStyle,
            gridColumn: 'span 1',
          }}
        >
          {body?.[0]?.score_obtained}
        </div>
      </div>
    </div>
  );
}
