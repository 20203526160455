import { useReport } from './hooks/use-context';

export function Providences() {
  const { providences: providencesData } = useReport();

  const cellStyle = {
    padding: '0px 2px',
    border: '1px solid black',
    textAlign: 'center' as const,
  };

  const valueStyle = {
    textAlign: 'center' as const,
    backgroundColor: '#ffffcc',
  };

  return (
    <div
      style={{
        display: 'grid',
        marginBottom: '4px',
        gridTemplateRows: '1fr 1fr',
        gap: '4px',
      }}
    >
      <div
        style={{
          display: 'grid',
          gridTemplateColumns: '2fr 1fr 1fr 1fr 1fr',
          gap: '4px',
        }}
      >
        <div
          style={{
            ...cellStyle,
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            backgroundColor: '#d3d3d3',
          }}
        >
          PROVIDÊNCIA (S)
        </div>
        <div
          style={{
            ...cellStyle,
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            backgroundColor: '#d3d3d3',
          }}
        >
          QUEM VAI FAZER
        </div>
        <div
          style={{
            ...cellStyle,
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            backgroundColor: '#d3d3d3',
          }}
        >
          INICIO
        </div>
        <div
          style={{
            ...cellStyle,
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            backgroundColor: '#d3d3d3',
          }}
        >
          FIM
        </div>
        <div
          style={{
            ...cellStyle,
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            backgroundColor: '#d3d3d3',
          }}
        >
          STATUS
        </div>
      </div>

      {providencesData?.map((providence, index) => {
        const {
          providences,
          providences_date_start,
          providences_date_end,
          provider,
        } = providence;

        return (
          <div
            key={`${providences.name}_${index}`}
            style={{
              display: 'grid',
              gridTemplateColumns: '2fr 1fr 1fr 1fr 1fr',
              gap: '4px',
            }}
          >
            <div
              style={{
                ...cellStyle,
                ...valueStyle,
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
              }}
            >
              {providences?.answers?.[0]?.content}
            </div>
            <div
              style={{
                ...cellStyle,
                ...valueStyle,
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
              }}
            >
              {provider?.answers?.[0]?.content}
            </div>
            <div
              style={{
                ...cellStyle,
                ...valueStyle,
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
              }}
            >
              {providences_date_start?.answers?.[0]?.content}
            </div>
            <div
              style={{
                ...cellStyle,
                ...valueStyle,
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
              }}
            >
              {providences_date_end?.answers?.[0]?.content}
            </div>
            <div
              style={{
                ...cellStyle,
                ...valueStyle,
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
              }}
            >
              EM ANDAMENTO
            </div>
          </div>
        );
      })}
    </div>
  );
}
