/* eslint-disable array-callback-return */
/* eslint-disable react/destructuring-assignment */

import { Concern } from './concern';
import { Header } from './header';
import { ReportContext } from './hooks/use-context';
import { InitialInfos } from './initial-infos';
import { RiskTable } from './risk-table';
import { Suggestions } from './suggestions';
import type {
  EventReportData,
  ParsedQuestions,
  QuestionsReporData,
} from './types';

export function GoiasaSafetyObservation(props: {
  reportData: EventReportData;
}) {
  const QUESTIONS_MAP: any = {
    '5aa9eeb7-5e14-4c5d-a912-b5978e75767a': 'observer_name',
    '19d91081-0757-4339-ba4f-41a3115eed36': 'observer_registration',

    '5c377535-0f0b-4c2d-93cc-08f42a08de15': 'observation_date',
    'a060a57e-f5a1-4f67-8dde-5871d38e541d': 'observation_time',
    'c128d2f9-dc5a-45dc-96c2-9b058d403877': 'observation_sector',
    '96cf8692-16e2-42b3-ba24-a378e1b9996a': 'observation_selection',
    '37ccb08c-bad1-42bd-9b9b-6412f5c2bd5b': 'observation_activity',

    'b1a3e182-ff62-4772-b95a-e5f048251dde': 'concern_1',
    'bd7f160d-bde5-4534-8761-73d49c3061c0': 'concern_2',
    'c39316a0-09fd-4b4a-aad5-819f74b4cd22': 'concern_3',
    '8dcddebf-5429-4dfe-b80a-cb4b9a0f875b': 'behavior_deviation_reason',
    
    'cf7500c0-1324-4a41-b6c4-f17171941f3b': 'hazard',
    'ae8be452-4b3a-4f1d-be60-5385a9250237': 'frequency',
    '5359be45-1732-45f8-bf7a-8d1259833649': 'scope',
    '4e632721-bf08-4406-bcd4-27250240077a': 'probability',
    'e1fa6e7d-f2dc-4307-8136-12dcd8123ac8': 'severity',
    'cd38e873-976c-4b18-b8b3-bf32deebf531': 'risk_level',
    'b7585579-f3e1-4afd-bf1f-8900af389a48': 'barrier_elimination_suggestions'
  };

  const values = props?.reportData?.body?.reduce((acc, item) => {
    item.sections.map((section) =>
      section.questions.map((question) => {
        if (question?.question_id && QUESTIONS_MAP[question?.question_id])
          acc[QUESTIONS_MAP[question?.question_id]] = question;
      }),
    );
    return acc;
  }, [] as Array<any>);

  const parsedProps: QuestionsReporData = {
    header: props?.reportData?.header,
    body: props?.reportData?.body,
    questions: (values as unknown) as ParsedQuestions,
  };

  return (
    <ReportContext value={parsedProps}>
      <div>
        <div
          style={{
            height: '90%',
            width: '100vw',
            flex: 1,
            backgroundColor: 'white',
            fontFamily: 'sans-serif',
          }}
        >
          <div
            style={{
              margin: '1rem auto',
              height: 'auto',
              maxWidth: '64rem',
            }}
          >
            <Header formName={props.reportData.body?.[0]?.form_name} />
            <div
              style={{
                height: '100%',
                width: '100%',
                borderWidth: '1px',
                borderStyle: 'solid',
                borderColor: '#000',
                backgroundColor: '#fff',
                padding: '1rem',
              }}
            >
              <InitialInfos />
              <Concern />
              <RiskTable />
              <Suggestions />
            </div>
          </div>
        </div>
      </div>
    </ReportContext>
  );
}
