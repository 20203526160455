type EnvConfigType = 'development' | 'staging' | 'production';

const ENV_CONFIG = process.env.REACT_APP_ENVIRONMENT as EnvConfigType;

export const DEFAULT_CONFIG = ENV_CONFIG || 'development';

export const APP_NAME = 'com.trackingtrade.wecheck';

export const APP_VERSION = '1.0.0';
export const LATITUDE_PLACEHOLDER: string = 'LATITUDE_PLACEHOLDER';
export const LONGITUDE_PLACEHOLDER: string = 'LONGITUDE_PLACEHOLDER';
export const DOMAIN_PLACEHOLDER: string = 'DOMAIN_PLACEHOLDER';
export const VALIDATION_CODE_PLACEHOLDER: string =
  'VALIDATION_CODE_PLACEHOLDER';

export const FIREBASE_CONFIG = {
  apiKey: 'AIzaSyBoCKi5D_B0nlvPRf9s47BJp26ZejdacwU',
  authDomain: 'we-check-55096.firebaseapp.com',
  databaseURL: 'https://we-check-55096.firebaseio.com/',
  projectId: 'we-check-55096',
  storageBucket: 'we-check-55096.appspot.com',
  messagingSenderId: '789836751458',
  appId: '1:789836751458:web:2f8c9b8d7fd793eae3a73b',
  measurementId: 'G-JS4TKMJ5LT',
};

const CONFIG = {
  development: {
    AUTH_URL: 'authenticate',
    BASE_URL: `${
      process.env.REACT_APP_PROTOCOL || 'https'
    }://${DOMAIN_PLACEHOLDER}${
      process.env.REACT_APP_DOMAIN_URL || '.wecheckapistaging.com.br'
    }/api/`,
    WORKSPACES_URL:
      process.env.REACT_APP_WORKSPACES_URL || `workspace_infos/get_infos`,
    SYNC_PUSH_URL: 'sync_push',
    SYNC_PULL_URL: 'sync_pull',
    USERS_URL: 'users',
    USER_PROFILES: 'user_profiles',
    ITEM_SYNC_STATES_URL: 'item_sync_states',
    ITEM_SYNC_ITEM_ID_URL: 'item_sync_item_id',
    DASHBOARDS_URL: 'dashboards',
    OUT_DASHBOARDS_URL: 'out_dashboards',
    DASHBOARD_SUPER_GROUPS: 'dashboard_super_groups',
    DASHBOARD_TEAMS: 'dashboard_teams',
    DASHBOARD_USERS_URL: 'dashboard_users',
    DASHBOARD_OUT_USERS_URL: 'dashboard_out_users',
    OUT_USERS_URL: 'out_users',
    FORMULARIES_URL: 'formularies',
    FORMULARY_GROUPS_URL: 'formulary_groups',
    LOCAL_GROUPS_URL: 'local_groups',
    OUT_FORMULARIES_URL: 'formularies_out_group',
    SECTIONS_URL: 'sections',
    QUESTIONS_URL: 'questions',
    QUESTIONS_WITH_OPTIONS_URL: 'questions_with_options',
    LOCALS_URL: 'locals',
    OUT_LOCALS_URL: 'locals_out_group',
    TAGS_URL: 'tags',
    TASKS_URL: 'tasks',
    TASKS_WITH_EVENT_URL: 'task_and_event',
    EVENTS_URL: 'events',
    SCHEDULES_URL: 'schedules',
    FILTERS_URL: 'filters',
    COLLECTIONS_URL: 'collections',
    COLLECTION_PROPERTIES_URL: `collection_properties`,
    COLLECTION_ITEMS_URL: `collection_items`,
    COLLECTIONS_GROUPS_URL: `collection_groups`,
    COLLECTIONS_WITH_GROUPS_URL: 'collections/collections_with_groups',
    CHECKLISTS_URL: `checklists`,
    EXPORTER_URL: 'exports',
    DASH_EXPORTER_URL: 'cards_by_dashboard/exports',
    IMPORTER_URL: 'imports',
    NOTIFICATION_URL: 'notifications',
    NOTIFICATION_USERS_URL: 'notification_users',
    PENDING_NOTIFICATIONS_URL: 'notification_pendings',
    DISCUSSION_TOPICS_URL: 'discussion_topics',
    DISCUSSION_LIKES_URL: 'discussion_likes',
    DISCUSSION_COMMENTS_URL: 'discussion_comments',
    DISCUSSION_STATUS_TYPES_URL: 'discussion_status_types',
    ITEM_REVIEW_URL: 'discussion_comments_review',
    ITEM_REVIEW_CANCEL_URL: 'discussion_comments_review_cancel',
    DISCUSSION_STATUS_TYPES_POSITION_URL:
      'discussion_status_types_update_positions',
    GALLERY_URL: 'pictures',
    ROLLBAR_ACCESS_TOKEN: '989fdc0f91f843169d637cd875d9fc2b',
    GOOGLE_PATH: 'placeholder',
    MAX_UPLOAD_FAIL_COUNT: 3,
    SYNC_POLLING_RATE: 1000,
    WEBSOCKET_URL: `wss://${DOMAIN_PLACEHOLDER}${
      process.env.REACT_APP_DOMAIN_URL || '.wecheckapistaging.com.br'
    }/cable`,
    DASHBOARD_MOCK: process.env.REACT_APP_DASHBOARD_MOCK === 'true',
    DASHBOARD_MOCK_URL:
      process.env.REACT_APP_DASHBOARD_MOCK_URL || 'http://localhost:8080/',
    MAP_URL:
      'https://www.google.com/maps/embed/v1/view?key=AIzaSyBD16SanUiX4-sQ2LtEprU4ahix5shPom8&center=LATITUDE_PLACEHOLDER,LONGITUDE_PLACEHOLDER&zoom=14',
    FIREBASE_CONFIG,
    COLLECTION_STORAGE: 'collection_files_process',
    PASSWORD_RECOVERY_URL: 'forgot_passwords',
    PASSWORD_RECOVERY_VALIDATE_URL:
      'https://we-check-api-staging.herokuapp.com/validation_token_user_registers/VALIDATION_CODE_PLACEHOLDER',
    ANSWERS_URL: 'answers',
    QUESTION_IMAGES_STORAGE: 'question_images_process',
    WORKFLOW_FILES_STORAGE: 'workflow_files_storage',
    ITEM_ATTACHMENTS_STORAGE: 'item_attachments_storage',
    ITEMS_URL: 'items',
    WORKFLOWS_URL: 'workflow/flows',
    TICKETS_URL: 'tickets/flows',
    REPORTS_API_URL: process.env.REACT_APP_REPORTS_API_URL,
    REPORTS_API_AUTHORIZATION_TOKEN:
      process.env.REACT_APP_REPORTS_API_AUTHORIZATION_TOKEN,
    REPORTS_FUNCTION_AUTHORIZATION_TOKEN:
      process.env.REACT_APP_REPORTS_FUNCTION_AUTHORIZATION_TOKEN,
    PRIVATE_KEY_ID: process.env.REACT_APP_PRIVATE_KEY_ID as string,
    REACT_APP_PRESENTATION_EXPORT_FUNCTION_URL: process.env
      .REACT_APP_PRESENTATION_EXPORT_FUNCTION_URL as string,
    MAX_GALLERY_PICTURES: 1000,
  },
  staging: {
    AUTH_URL: 'authenticate',
    BASE_URL: `https://${DOMAIN_PLACEHOLDER}${
      process.env.REACT_APP_DOMAIN_URL || '.wecheckapp.com.br'
    }/api/`,
    WORKSPACES_URL:
      process.env.REACT_APP_WORKSPACES_URL || `workspace_infos/get_infos`,
    SYNC_PUSH_URL: 'sync_push',
    SYNC_PULL_URL: 'sync_pull',
    COLLECTIONS_GROUPS_URL: `collection_groups`,
    USERS_URL: 'users',
    USER_PROFILES: 'user_profiles',
    ITEM_SYNC_STATES_URL: 'item_sync_states',
    ITEM_SYNC_ITEM_ID_URL: 'item_sync_item_id',
    DASHBOARDS_URL: 'dashboards',
    OUT_DASHBOARDS_URL: 'out_dashboards',
    DASHBOARD_SUPER_GROUPS: 'dashboard_super_groups',
    DASHBOARD_TEAMS: 'dashboard_teams',
    DASHBOARD_USERS_URL: 'dashboard_users',
    DASHBOARD_OUT_USERS_URL: 'dashboard_out_users',
    OUT_USERS_URL: 'out_users',
    FORMULARIES_URL: 'formularies',
    FORMULARY_GROUPS_URL: 'formulary_groups',
    LOCAL_GROUPS_URL: 'local_groups',
    OUT_FORMULARIES_URL: 'formularies_out_group',
    SECTIONS_URL: 'sections',
    QUESTIONS_URL: 'questions',
    QUESTIONS_WITH_OPTIONS_URL: 'questions_with_options',
    LOCALS_URL: 'locals',
    OUT_LOCALS_URL: 'locals_out_group',
    TAGS_URL: 'tags',
    TASKS_URL: 'tasks',
    TASKS_WITH_EVENT_URL: 'task_and_event',
    EVENTS_URL: 'events',
    SCHEDULES_URL: 'schedules',
    FILTERS_URL: 'filters',
    COLLECTIONS_URL: 'collections',
    COLLECTION_PROPERTIES_URL: `collection_properties`,
    COLLECTION_ITEMS_URL: `collection_items`,
    COLLECTIONS_WITH_GROUPS_URL: 'collections/collections_with_groups',
    CHECKLISTS_URL: `checklists`,
    EXPORTER_URL: 'exports',
    DASH_EXPORTER_URL: 'cards_by_dashboard/exports',
    IMPORTER_URL: 'imports',
    NOTIFICATION_URL: 'notifications',
    NOTIFICATION_USERS_URL: 'notification_users',
    PENDING_NOTIFICATIONS_URL: 'notification_pendings',
    DISCUSSION_TOPICS_URL: 'discussion_topics',
    DISCUSSION_LIKES_URL: 'discussion_likes',
    DISCUSSION_COMMENTS_URL: 'discussion_comments',
    DISCUSSION_STATUS_TYPES_URL: 'discussion_status_types',
    ITEM_REVIEW_URL: 'discussion_comments_review',
    ITEM_REVIEW_CANCEL_URL: 'discussion_comments_review_cancel',
    DISCUSSION_STATUS_TYPES_POSITION_URL:
      'discussion_status_types_update_positions',
    GALLERY_URL: 'pictures',
    ROLLBAR_ACCESS_TOKEN: '989fdc0f91f843169d637cd875d9fc2b',
    GOOGLE_PATH: 'placeholder',
    MAX_UPLOAD_FAIL_COUNT: 3,
    SYNC_POLLING_RATE: 1000,
    WEBSOCKET_URL: `wss://${DOMAIN_PLACEHOLDER}${
      process.env.REACT_APP_DOMAIN_URL || '.wecheckapistaging.com.br'
    }/cable`,
    DASHBOARD_MOCK: process.env.REACT_APP_DASHBOARD_MOCK === 'true',
    DASHBOARD_MOCK_URL:
      process.env.REACT_APP_DASHBOARD_MOCK_URL || 'http://localhost:8080/',
    MAP_URL:
      'https://www.google.com/maps/embed/v1/view?key=AIzaSyBD16SanUiX4-sQ2LtEprU4ahix5shPom8&center=LATITUDE_PLACEHOLDER,LONGITUDE_PLACEHOLDER&zoom=14',
    FIREBASE_CONFIG,
    COLLECTION_STORAGE: 'collection_files_process',
    PASSWORD_RECOVERY_URL: 'forgot_passwords',
    PASSWORD_RECOVERY_VALIDATE_URL:
      'https://we-check-api-staging.herokuapp.com/validation_token_user_registers/VALIDATION_CODE_PLACEHOLDER',
    ANSWERS_URL: 'answers',
    QUESTION_IMAGES_STORAGE: 'question_images_process',
    WORKFLOW_FILES_STORAGE: 'workflow_files_storage',
    ITEM_ATTACHMENTS_STORAGE: 'item_attachments_storage',
    ITEMS_URL: 'items',
    WORKFLOWS_URL: 'workflow/flows',
    TICKETS_URL: 'tickets/flows',
    REPORTS_API_URL: process.env.REACT_APP_REPORTS_API_URL,
    REPORTS_API_AUTHORIZATION_TOKEN:
      process.env.REACT_APP_REPORTS_API_AUTHORIZATION_TOKEN,
    REPORTS_FUNCTION_AUTHORIZATION_TOKEN:
      process.env.REACT_APP_REPORTS_FUNCTION_AUTHORIZATION_TOKEN,
    PRIVATE_KEY_ID: process.env.REACT_APP_PRIVATE_KEY_ID as string,
    REACT_APP_PRESENTATION_EXPORT_FUNCTION_URL: process.env
      .REACT_APP_PRESENTATION_EXPORT_FUNCTION_URL as string,
    MAX_GALLERY_PICTURES: 1000,
  },
  production: {
    AUTH_URL: 'authenticate',
    BASE_URL: `https://${DOMAIN_PLACEHOLDER}${
      process.env.REACT_APP_DOMAIN_URL || '.wecheckapp.com.br'
    }/api/`,
    WORKSPACES_URL:
      process.env.REACT_APP_WORKSPACES_URL || `workspace_infos/get_infos`,
    SYNC_PUSH_URL: 'sync_push',
    SYNC_PULL_URL: 'sync_pull',
    USERS_URL: 'users',
    USER_PROFILES: 'user_profiles',
    ITEM_SYNC_STATES_URL: 'item_sync_states',
    ITEM_SYNC_ITEM_ID_URL: 'item_sync_item_id',
    DASHBOARDS_URL: 'dashboards',
    OUT_DASHBOARDS_URL: 'out_dashboards',
    DASHBOARD_SUPER_GROUPS: 'dashboard_super_groups',
    DASHBOARD_TEAMS: 'dashboard_teams',
    DASHBOARD_USERS_URL: 'dashboard_users',
    DASHBOARD_OUT_USERS_URL: 'dashboard_out_users',
    OUT_USERS_URL: 'out_users',
    FORMULARIES_URL: 'formularies',
    FORMULARY_GROUPS_URL: 'formulary_groups',
    LOCAL_GROUPS_URL: 'local_groups',
    OUT_FORMULARIES_URL: 'formularies_out_group',
    SECTIONS_URL: 'sections',
    QUESTIONS_URL: 'questions',
    QUESTIONS_WITH_OPTIONS_URL: 'questions_with_options',
    LOCALS_URL: 'locals',
    OUT_LOCALS_URL: 'locals_out_group',
    TAGS_URL: 'tags',
    TASKS_URL: 'tasks',
    EVENTS_URL: 'events',
    TASKS_WITH_EVENT_URL: 'task_and_event',
    SCHEDULES_URL: 'schedules',
    FILTERS_URL: 'filters',
    COLLECTIONS_URL: 'collections',
    COLLECTION_PROPERTIES_URL: `collection_properties`,
    COLLECTION_ITEMS_URL: `collection_items`,
    COLLECTIONS_GROUPS_URL: `collection_groups`,
    COLLECTIONS_WITH_GROUPS_URL: 'collections/collections_with_groups',
    CHECKLISTS_URL: `checklists`,
    EXPORTER_URL: 'exports',
    DASH_EXPORTER_URL: 'cards_by_dashboard/exports',
    IMPORTER_URL: 'imports',
    NOTIFICATION_URL: 'notifications',
    NOTIFICATION_USERS_URL: 'notification_users',
    PENDING_NOTIFICATIONS_URL: 'notification_pendings',
    DISCUSSION_TOPICS_URL: 'discussion_topics',
    DISCUSSION_LIKES_URL: 'discussion_likes',
    DISCUSSION_COMMENTS_URL: 'discussion_comments',
    DISCUSSION_STATUS_TYPES_URL: 'discussion_status_types',
    ITEM_REVIEW_URL: 'discussion_comments_review',
    ITEM_REVIEW_CANCEL_URL: 'discussion_comments_review_cancel',
    DISCUSSION_STATUS_TYPES_POSITION_URL:
      'discussion_status_types_update_positions',
    GALLERY_URL: 'pictures',
    ROLLBAR_ACCESS_TOKEN: '989fdc0f91f843169d637cd875d9fc2b',
    GOOGLE_PATH: 'placeholder',
    MAX_UPLOAD_FAIL_COUNT: 3,
    SYNC_POLLING_RATE: 1000,
    WEBSOCKET_URL: `wss://${DOMAIN_PLACEHOLDER}${
      process.env.REACT_APP_DOMAIN_URL || '.wecheckapp.com.br'
    }/cable`,
    DASHBOARD_MOCK: process.env.REACT_APP_DASHBOARD_MOCK === 'true',
    DASHBOARD_MOCK_URL:
      process.env.REACT_APP_DASHBOARD_MOCK_URL || 'http://localhost:8080/',
    MAP_URL:
      'https://www.google.com/maps/embed/v1/view?key=AIzaSyBD16SanUiX4-sQ2LtEprU4ahix5shPom8&center=LATITUDE_PLACEHOLDER,LONGITUDE_PLACEHOLDER&zoom=14',
    FIREBASE_CONFIG,
    COLLECTION_STORAGE: 'collection_files_process',
    PASSWORD_RECOVERY_URL: 'forgot_passwords',
    PASSWORD_RECOVERY_VALIDATE_URL:
      'https://we-check-api.herokuapp.com/validation_token_user_registers/VALIDATION_CODE_PLACEHOLDER',
    ANSWERS_URL: 'answers',
    QUESTION_IMAGES_STORAGE: 'question_images_process',
    WORKFLOW_FILES_STORAGE: 'workflow_files_storage',
    ITEM_ATTACHMENTS_STORAGE: 'item_attachments_storage',
    ITEMS_URL: 'items',
    WORKFLOWS_URL: 'workflow/flows',
    TICKETS_URL: 'tickets/flows',
    REPORTS_API_URL: process.env.REACT_APP_REPORTS_API_URL,
    REPORTS_API_AUTHORIZATION_TOKEN:
      process.env.REACT_APP_REPORTS_API_AUTHORIZATION_TOKEN,
    REPORTS_FUNCTION_AUTHORIZATION_TOKEN:
      process.env.REACT_APP_REPORTS_FUNCTION_AUTHORIZATION_TOKEN,
    PRIVATE_KEY_ID: process.env.REACT_APP_PRIVATE_KEY_ID as string,
    REACT_APP_PRESENTATION_EXPORT_FUNCTION_URL: process.env
      .REACT_APP_PRESENTATION_EXPORT_FUNCTION_URL as string,
    MAX_GALLERY_PICTURES: 1000,
  },
};

export const env = DEFAULT_CONFIG;

const config = CONFIG[env];

export default config;
