import { useReport } from './hooks/use-context';

export function CollaboratorData() {
  const { body } = useReport();

  const { name, record_responsible, foreign_name } = body;

  const cellStyle = {
    padding: '0px 2px',
    border: '1px solid black',
    textAlign: 'left' as const,
  };

  const valueStyle = {
    textAlign: 'center' as const,
    backgroundColor: '#ffffcc',
  };

  return (
    <div style={{ marginBottom: '4px' }}>
      <div
        style={{
          width: '100%',
          backgroundColor: '#d3d3d3',
          textAlign: 'center',
          marginBottom: '4px',
        }}
      >
        DADOS DO COLABORADOR
      </div>

      <div
        style={{
          display: 'grid',
          gridTemplateColumns: '3fr 8fr',
          fontFamily: 'Arial, sans-serif',
          gap: '10px',
          marginBottom: '4px',
        }}
      >
        {/* col 1 */}
        <div
          style={{
            display: 'grid',
            gridTemplateRows: 'repeat(6, 1fr)',
            rowGap: '4px',
          }}
        >
          <div style={cellStyle}>NOME</div>
          <div style={cellStyle}>CADASTRO</div>
          <div style={cellStyle}>AREA ATUAÇÃO</div>
          <div style={cellStyle}>COORDENADOR</div>
          <div style={cellStyle}>GESTOR</div>
          <div style={cellStyle}>RESPONSÁVEL PELO REGISTRO</div>
        </div>

        {/* col 2 */}
        <div
          style={{
            display: 'grid',
            gridTemplateRows: 'repeat(6, 1fr)',
            rowGap: '4px',
          }}
        >
          <div style={{ ...cellStyle, ...valueStyle }}>
            {name?.answers?.[0]?.content || foreign_name?.answers?.[0]?.content}
          </div>
          <div
            style={{
              display: 'grid',
              gridTemplateColumns: '2fr 2fr 4fr',
              gap: '4px',
            }}
          >
            <div style={{ ...cellStyle, ...valueStyle }}>
              {name?.answers?.[0]?.collection_item?.code}
            </div>
            <div style={cellStyle}>CARGO</div>
            <div style={{ ...cellStyle, ...valueStyle }}>
              {
                name?.answers?.[0]?.collection_item?.properties.find(
                  (prop) => prop.name === 'CARGO',
                )?.content
              }
            </div>
          </div>
          <div style={{ ...cellStyle, ...valueStyle }}>
            {
              name?.answers?.[0]?.collection_item?.properties.find(
                (prop) => prop.name === 'AREA ATUAÇÃO',
              )?.content
            }
          </div>
          <div style={{ ...cellStyle, ...valueStyle }}>
            {
              name?.answers?.[0]?.collection_item?.properties.find(
                (prop) => prop.name === 'COORDENADOR',
              )?.content
            }
          </div>
          <div style={{ ...cellStyle, ...valueStyle }}>
            {
              name?.answers?.[0]?.collection_item?.properties.find(
                (prop) => prop.name === 'GESTOR',
              )?.content
            }
          </div>
          <div style={{ ...cellStyle, ...valueStyle }}>
            {record_responsible?.answers?.[0]?.content}
          </div>
        </div>
      </div>
    </div>
  );
}
