import { useReport } from './hooks/use-context';

export function Members() {
  const { body, header } = useReport();

  const infoSection = body?.sections.find(
    (section) => section.section_name === 'Informações',
  );

  return (
    <div style={{ fontSize: '10px', breakInside: 'avoid' }}>
      <div
        style={{
          display: 'grid',
          gridTemplateColumns: '5fr 8fr 5fr 12fr',
        }}
      >
        <div
          style={{
            display: 'grid',
            gridTemplateRows: 'repeat(3, 1fr)',
          }}
        >
          <div
            style={{
              gridRow: '1 / span 3',
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
              textAlign: 'center',
              fontWeight: 'bold',
              padding: '0px 5px',
              borderRight: '1px #000 solid',
              borderBottom: '1px #000 solid',
            }}
          >
            Participantes
          </div>
        </div>

        <div
          style={{
            display: 'grid',
            gridTemplateRows: '1fr 1fr 1fr',
          }}
        >
          <div
            style={{
              display: 'flex',
              padding: '4px',
              justifyContent: 'center',
              alignItems: 'center',
              textAlign: 'center',
              fontWeight: 'bold',
              borderRight: '1px #000 solid',
              borderBottom: '1px #000 solid',
            }}
          >
            Nome
          </div>

          <div
            style={{
              display: 'flex',
              padding: '4px',
              justifyContent: 'center',
              alignItems: 'center',
              textAlign: 'center',
              borderRight: '1px #000 solid',
              borderBottom: '1px #000 solid',
            }}
          >
            <div>
              {
                infoSection?.questions.find(
                  (question) => question.name === 'Nome do participante:',
                )?.answers[0].content
              }
            </div>
          </div>

          <div
            style={{
              display: 'flex',
              padding: '4px',
              borderRight: '1px #000 solid',
              borderBottom: '1px #000 solid',
            }}
          ></div>
        </div>

        <div
          style={{
            display: 'grid',
            gridTemplateRows: '1fr 1fr 1fr',
          }}
        >
          <div
            style={{
              display: 'flex',
              padding: '4px',
              justifyContent: 'center',
              alignItems: 'center',
              textAlign: 'center',
              fontWeight: 'bold',
              borderRight: '1px #000 solid',
              borderBottom: '1px #000 solid',
            }}
          >
            Cargo/Função
          </div>

          <div
            style={{
              display: 'flex',
              padding: '4px',
              justifyContent: 'center',
              alignItems: 'center',
              textAlign: 'center',
              borderRight: '1px #000 solid',
              borderBottom: '1px #000 solid',
            }}
          >
            <div>
              {
                infoSection?.questions
                  .find((question) => question.name === 'Nome do participante:')
                  ?.answers[0].collection_item.properties.find(
                    (property) => property.name === 'CARGO',
                  )?.content
              }
            </div>
          </div>

          <div
            style={{
              display: 'flex',
              padding: '4px',
              borderRight: '1px #000 solid',
              borderBottom: '1px #000 solid',
            }}
          ></div>
        </div>

        <div
          style={{
            display: 'grid',
            gridTemplateRows: '1fr 1fr 1fr',
          }}
        >
          <div
            style={{
              display: 'flex',
              padding: '4px',
              justifyContent: 'center',
              alignItems: 'center',
              textAlign: 'center',
              fontWeight: 'bold',
              borderBottom: '1px #000 solid',
            }}
          >
            Gestor Responsável
          </div>

          <div
            style={{
              display: 'flex',
              padding: '4px',
              borderBottom: '1px #000 solid',
            }}
          >
            <div style={{ fontWeight: 'bold' }}>Nome:</div>
            <div
              style={{
                flex: 1,
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
              }}
            >
              {
                infoSection?.questions
                  .find((question) => question.name === 'Nome do participante:')
                  ?.answers[0].collection_item.properties.find(
                    (property) => property.name === 'GESTOR',
                  )?.content
              }
            </div>
          </div>

          <div
            style={{
              display: 'flex',
              padding: '4px',
              borderBottom: '1px #000 solid',
            }}
          >
            <div style={{ fontWeight: 'bold' }}>Data:</div>
            <div
              style={{
                flex: 1,
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
              }}
            >
              {header.event_created_at}
            </div>
          </div>
        </div>
      </div>

      <div
        style={{
          display: 'grid',
          gridTemplateColumns: '5fr 25fr',
        }}
      >
        <div
          style={{
            display: 'grid',
            gridTemplateRows: '1fr',
          }}
        >
          <div
            style={{
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
              textAlign: 'center',
              backgroundColor: '#fff',
              fontWeight: 'bold',
              padding: '0px 2px',
              borderRight: '1px #000 solid',
              borderBottom: '1px #000 solid',
            }}
          >
            Responsável pela área
          </div>
        </div>

        <div
          style={{
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            textAlign: 'center',
            padding: '4px',
            borderBottom: '1px #000 solid',
          }}
        >
          <div>
            {
              infoSection?.questions.find(
                (question) => question.name === 'Nome do responsável da área:',
              )?.answers[0].content
            }
          </div>
        </div>
      </div>
    </div>
  );
}
