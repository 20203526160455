/* eslint-disable @typescript-eslint/ban-types */

import Loading from '@/components_v2/Loading';
import { useAuth } from '@/hooks/Authentication/auth';
import { useUserChangesListener } from '@/hooks/Authentication/userChangesListener';
import { useUserSettings } from '@/hooks/Settings/userSettings';
import { useWorkspace } from '@/hooks/workspace';
import { IRouteData } from '@/routes/interfaces';
import { useNavigation } from '@hooks/navigation';
import React, { Suspense, useCallback, useEffect, useState } from 'react';
import { useMediaQuery } from 'react-responsive';

import Main from './Layout';

const readAllRoutes = {
  dashboard: {
    name: 'dashboard',
    title: 'screens.dashboard',
    path: '/dashboard',
    type: 'primary',
  },
  audit: {
    name: 'audit',
    title: 'screens.audit',
    path: '/audit',
    recursive: 'true',
    type: 'primary',
  },
};

const Layout: React.FC = ({ children }) => {
  const {
    pathname,
    getAppRouteData,
    navigate,
    getMainRoute,
    getRouteData,
    setAppRoutes,
  } = useNavigation();
  const { getPreferences, setPreferences } = useUserSettings();
  const { workspaceData } = useWorkspace();
  const isMobile: boolean = useMediaQuery({ query: '(max-width: 768px)' });
  const [isCollapsed, setIsCollapsed] = useState<boolean>(
    getPreferences().subMenuCollapsed || false,
  );
  const [appRoute] = useState<IRouteData>(() => {
    return getAppRouteData();
  });
  const [isMainMenuOpen, setMainMenuOpenState] = useState<boolean>(false);
  const [mainSelected, setMainSelected] = useState<string | undefined>(
    undefined,
  );
  const [selectedRoute, setSelectedRoute] = useState<{
    main: string;
    sub: string;
  }>({ main: '', sub: '' });
  const [mainList, setMainList] = useState<IRouteData[]>(() => {
    if (appRoute.children) {
      return Object.values(appRoute.children).filter(
        (route) => !route.unfinished,
      );
    }
    return [];
  });
  const [subList, setSubList] = useState<IRouteData[]>([]);
  const { user } = useAuth();

  useEffect(() => {
    const collapsed = getPreferences().subMenuCollapsed;
    if (collapsed !== undefined) {
      setIsCollapsed(collapsed);
    }
  }, [getPreferences]);

  const toggleCollapseSubmenu = useCallback(() => {
    setPreferences({ subMenuCollapsed: !isCollapsed });
    setIsCollapsed(!isCollapsed);
  }, [isCollapsed, setPreferences]);

  useEffect(() => {
    const index = mainList.findIndex((item) => item.name === mainSelected);
    const main = mainList[index];

    if (main && main.children) {
      setSubList(
        Object.values(main.children).filter((route) => !route.unfinished),
      );
      // setSubSelected(Object.values(main.children)[0].name);
    } else if (main && !main.children) {
      if (pathname.includes(main!.path!)) return;
      setSubList([]);
      setMainMenuOpenState(false);

      navigate(`/app${main.path}`);
    }
  }, [mainList, mainSelected, pathname, navigate]);

  const setMainMenuOpen = useCallback((state: boolean) => {
    setMainMenuOpenState(state);
  }, []);

  useEffect(() => {
    const main = getMainRoute();
    const sub = getRouteData();

    if (main) {
      setMainSelected(main.name);
      setSelectedRoute({ main: main.name, sub: sub ? sub.name : '' });
    }
  }, [getMainRoute, getRouteData]);

  useEffect(() => {
    if (
      (workspaceData.routes && Object.keys(workspaceData.routes).length > 0) ||
      user?.read_all ||
      user?.dashboard_only
    ) {
      const routes: any =
        user?.read_all || user?.dashboard_only
          ? readAllRoutes
          : workspaceData.routes;
      setAppRoutes(routes);
      const newAppRouteData = getAppRouteData();
      if (newAppRouteData.children) {
        const main = Object.values(newAppRouteData.children).filter(
          (route) => !route.unfinished,
        );
        setMainList(main);
      }
    }
  }, [
    workspaceData,
    setAppRoutes,
    getAppRouteData,
    user?.read_all,
    user?.dashboard_only,
  ]);

  const onMainSelected = useCallback((selected: string) => {
    setMainSelected(selected);
  }, []);

  const onSubSelected = useCallback(
    (selected: string) => {
      setSelectedRoute({ main: selectedRoute.main, sub: selected });
      const mainIndex = mainList.findIndex(
        (item) => item.name === mainSelected,
      );
      const main = mainList[mainIndex];
      const sub = subList.find((item) => {
        if (!item.children) return item.name === selected;

        const aux = Object.keys(item.children).includes(selected);

        return aux;
      });

      if (main && sub) {
        const subPath = sub.children ? sub.children[selected].path : sub.path;

        navigate(`/app${main.path}${subPath}`);
        setMainMenuOpenState(false);
      }
    },
    [mainList, mainSelected, subList, navigate, selectedRoute.main],
  );

  return (
    <Main
      mainSelected={mainSelected || ''}
      setMainSelected={onMainSelected}
      subSelected={mainSelected === selectedRoute.main ? selectedRoute.sub : ''}
      setSubSelected={onSubSelected}
      isMainMenuOpen={isMainMenuOpen}
      setMainMenuOpen={setMainMenuOpen}
      subList={subList}
      showSubMenu={subList && subList.length > 0}
      mainList={mainList}
      isCollapsed={isCollapsed}
      isMenuDisabled={workspaceData.menu_disabled || false}
      isMobile={isMobile}
      toggleCollapsible={toggleCollapseSubmenu}
      setSubList={setSubList}
    >
      {children}
    </Main>
  );
};

export const withLayout = <P extends object>(
  Component: React.ComponentType<P>,
) => {
  const Wrapped: React.ComponentType<P> = (props) => {
    const { key } = useUserChangesListener();
    return (
      <Layout key={key}>
        <Suspense fallback={<Loading />}>
          <Component {...props} />
        </Suspense>
      </Layout>
    );
  };

  return Wrapped;
};

export default Layout;
