import _ from 'lodash';
import { useMemo } from 'react';
import { useReport } from './hooks/use-context';

export function Pictures() {
  const { body } = useReport();

  const pictures = useMemo(() => {
    if (!body) return [];

    return _.orderBy(
      body?.[0]?.sections?.map((section) => section.questions).flat(),
      'position',
      'desc',
    )
      .filter((e) => ['gallery', 'picture'].includes(e.type))
      .map((q) => {
        const questionNumber = q.name.match(/^\d+/)?.[0]; // Extract the question number
        return q.answers.map((answer) => ({
          ...answer,
          questionNumber, // Add the question number to each answer
        }));
      })
      .flat(); // Flatten the array to get a single array of answers
  }, [body]);

  return (
    <div style={{ breakInside: 'avoid' }}>
      <div
        style={{
          width: '100%',
          backgroundColor: '#d3d3d3',
          textAlign: 'center',
          padding: '0.5rem',
          fontWeight: 'bold',
          fontSize: '1.2rem',
        }}
      >
        REGISTRO FOTOGRÁFICO
      </div>
      <div
        style={{
          display: 'grid',
          gridTemplateColumns: 'repeat(auto-fit, minmax(200px, 1fr))',
          gap: '1rem',
          backgroundColor: '#ffffcc',
          padding: '1rem',
          alignItems: 'center',
          justifyContent: 'center',
        }}
      >
        {pictures?.map((answer, index) => (
          <div
            key={`${answer.id}-${index}`} // Use a unique key for each answer
            style={{
              overflow: 'hidden',
              display: 'flex',
              flexDirection: 'column',
              justifyContent: 'center',
              alignItems: 'center',
            }}
          >
            <img
              style={{
                objectFit: 'contain',
                width: '100%',
                height: '100%',
                maxHeight: '300px',
              }}
              src={`${answer.content || ''}`}
              alt="img"
            />
            {answer.questionNumber && (
              <div style={{ marginTop: '0.5rem', fontWeight: 'bold' }}>
                NC {answer.questionNumber}
              </div>
            )}
          </div>
        ))}
      </div>
    </div>
  );
}