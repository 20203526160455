/* eslint-disable array-callback-return */
/* eslint-disable react/destructuring-assignment */
import { Header } from './header';
import { ReportContext } from './hooks/use-context';
import { InitialInfos } from './initial-infos';
import { Labels } from './labels';
import { Members } from './members';
import { Pictures } from './pictures';
import { Table } from './table';
import type { EventReportData, QuestionsReporData } from './types';

export function GoiasaReport(props: { reportData: EventReportData }) {
  const parsedProps: QuestionsReporData = {
    header: props?.reportData?.header,
    body: props?.reportData?.body,
  };
  return (
    <ReportContext value={parsedProps}>
      <div style={{breakAfter: 'page'}}>
        <div
          style={{
            height: '90%',
            width: '100vw',
            flex: 1,
            backgroundColor: 'white',
            fontFamily: 'sans-serif',
          }}
        >
          <div
            style={{
              margin: '1rem auto',
              height: 'auto',
              maxWidth: '64rem',
            }}
          >
            <Header formName={parsedProps.body?.form_name} />
            <div
              style={{
                height: '100%',
                width: '100%',
                borderRadius: '2px',
                borderWidth: '1px',
                borderStyle: 'solid',
                borderColor: '#000',
                backgroundColor: '#fff',
              }}
            >
              <InitialInfos />
              <Table />
              <Members />
              <Labels />
              <Pictures />
            </div>
          </div>
        </div>
      </div>
    </ReportContext>
  );
}
