/* eslint-disable no-underscore-dangle */
import { Combobox } from '@/components_v2/Combobox/Combobox';
import { InputDiv } from '@/components_v2/Questions/type/common/styles';
import reduceArrayToListText from '@/utils/Text/reduceArrayToListText';
import {
  Box,
  Button,
  Checkbox,
  Tab,
  TabList,
  TabPanel,
  TabPanels,
  Tabs,
} from '@chakra-ui/react';
import React, { forwardRef, useCallback, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { Div } from '../BaseInput/styles';
import { AccessSettingsData, PopoverAccessSettingsProps } from './interfaces';
import { useOptionsQuery } from './utils/useOptionsQuery';

export interface OnChangeData {
  users: string[];
  teams: string[];
}

export type AccessSettingsProps = {
  usersUrl: string;
  teamsUrl: string;
  initialValues?: AccessSettingsData;
  onChange: (data: OnChangeData) => void;
  disabled?: boolean;
};

export type SettingTabProps = {
  selected: Option[];
  onSelect: (item: Option) => void;
  setSearchTerm: (search: string) => void;
  searchTerm: string;
  url: string;
  tabKey: 'users' | 'teams';
};

const SettingTab = forwardRef<HTMLDivElement, SettingTabProps>((props, ref) => {
  const { onSelect, selected, url, searchTerm, setSearchTerm, tabKey } = props;
  const query = useOptionsQuery([url, `&search=${searchTerm}`]);

  const parsedItems = React.useMemo(
    () => (query.data?.items || query.data || []) as Option[],
    [query.data],
  );

  const renderItem = React.useCallback(
    (item: Option) => {
      const isSelected = selected.findIndex((e) => e.id === item.id) !== -1;

      const handleSelect = (event: any) => {
        event.preventDefault();
        event.stopPropagation();
        onSelect(item);
      };

      return (
        <Combobox.ListItem
          onClick={handleSelect}
          leftContent={
            <Checkbox
              size="lg"
              isChecked={isSelected}
              onChange={handleSelect}
            />
          }
        >
          {item.name}
        </Combobox.ListItem>
      );
    },
    [onSelect, selected],
  );

  const renderItems = React.useCallback(
    (mode: 'all' | 'selected' = 'all') => {
      if (mode === 'selected') {
        return selected?.map(renderItem);
      }
      return parsedItems
        ?.filter(
          (element) => selected.findIndex((e) => e.id === element.id) === -1,
        )
        .map(renderItem);
    },
    [parsedItems, renderItem, selected],
  );

  return (
    <>
      <Combobox.Search
        key={tabKey}
        tabKey={tabKey}
        onChange={(e) => setSearchTerm(e.target.value)}
      />
      {!!selected.length && (
        <Combobox.ListSection label="Selecionados">
          {renderItems('selected')}
        </Combobox.ListSection>
      )}
      {!!parsedItems?.length && (
        <Combobox.ListSection label="Todos">
          {renderItems()}
        </Combobox.ListSection>
      )}
    </>
  );
});

const PopoverAccessSettings = forwardRef<
  HTMLDivElement,
  PopoverAccessSettingsProps
>(
  (
    {
      onChange,
      initialValues,
      teamsUrl,
      usersUrl,
      renderTrigger,
      disabled = false,
    },
    ref,
  ) => {
    const [data, setData] = useState<AccessSettingsData>(
      initialValues || {
        teams: [],
        users: [],
      },
    );
    const tabs: ('users' | 'teams')[] = ['users', 'teams'];
    const [currentTab, setCurrentTab] = useState<'users' | 'teams'>('users');
    const [searchTerm, setSearchTerm] = useState<string>('');
    const [isOpen, setIsOpen] = useState<boolean>(false);
    const usersQuery = useOptionsQuery([usersUrl, `&search=${searchTerm}`]);
    const teamsQuery = useOptionsQuery([teamsUrl, `&search=${searchTerm}`]);

    const query = useMemo(
      () => ({
        users: usersQuery,
        teams: teamsQuery,
      }),
      [teamsQuery, usersQuery],
    );

    const filteredUsers = data.users.filter(({ id }) => {
      const ids = query?.users?.data?.items.map((item) => item.id);
      return ids?.includes(id);
    });

    const filteredTeams = data.teams.filter(({ id }) => {
      const ids = query?.teams?.data?.items.map((item) => item.id);
      return ids?.includes(id);
    });

    const parsedItems = useMemo(
      () =>
        (query[currentTab].data?.items ||
          query[currentTab].data ||
          []) as Option[],
      [currentTab, query],
    );

    const { t } = useTranslation();

    const handleChange = useCallback(
      (option: Option, type: 'users' | 'teams') => {
        const old = { ...data };
        let currentCollection = old[type];

        if (currentCollection.findIndex((e) => e.id === option.id) === -1) {
          currentCollection.push(option);
        } else {
          currentCollection = currentCollection.filter(
            (e) => e.id !== option.id,
          );
        }

        const newState = {
          ...old,
          [type]: currentCollection,
        };
        setData(newState);

        onChange({
          teams: newState?.teams && newState?.teams.map((e) => e.id),
          users: newState?.users && newState?.users.map((e) => e.id),
        });
      },
      [data, onChange],
    );

    const handleSelectAll = () => {
      const newState = {
        ...data,
        [currentTab]: parsedItems,
      };
      setData(newState);

      onChange({
        teams: newState?.teams && newState?.teams.map((e) => e.id),
        users: newState?.users && newState?.users.map((e) => e.id),
      });
    };

    const isEnvironments = usersUrl.includes('user_type');

    const formattedText = useMemo(() => {
      return (data.teams && data.teams.length !== 0) ||
        (data.users && data.users.length !== 0)
        ? reduceArrayToListText({
            arr: isEnvironments
              ? [...data.users.map((option) => option.name)]
              : [
                  ...data.users.map((option) => option.name),
                  ...data.teams.map((option) => option.name),
                ],
          })
        : t('common.select_value');
    }, [data, isEnvironments, t]);

    const _renderTrigger = useCallback(() => {
      if (renderTrigger) {
        return renderTrigger(formattedText);
      }

      return (
        <InputDiv
          backgroundColor="#FFF"
          onClick={() => setIsOpen(true)}
          ref={ref}
        >
          <Div>{formattedText}</Div>
        </InputDiv>
      );
    }, [formattedText, renderTrigger]);

    return (
      <Combobox.Parent
        TriggerComponent={_renderTrigger()}
        placement='auto'
        onOpen={() => setIsOpen(true)}
        onClose={() => setIsOpen(false)}
        isOpen={isOpen}
        renderFooter={(onClose) => (
          <Box>
            <Button
              float="right"
              colorScheme="brand"
              onClick={() => {
                onClose();
                setSearchTerm('');
                setIsOpen(false);
              }}
              marginLeft="10px"
            >
              Confirmar
            </Button>
            <Button
              float="right"
              marginLeft="10px"
              colorScheme="gray"
              onClick={handleSelectAll}
            >
              Selecionar todos
            </Button>
            <Button
              float="right"
              colorScheme="gray"
              onClick={() => {
                onClose();
                setSearchTerm('');
                setIsOpen(false);
              }}
            >
              Cancelar
            </Button>
          </Box>
        )}
      >
        <Tabs
          onChange={(tab) => setCurrentTab(tabs[tab])}
          lazyBehavior="unmount"
        >
          <TabList>
            <Tab key="users">Usuários</Tab>
            {!isEnvironments && <Tab key="teams">Times</Tab>}
          </TabList>
          <TabPanels>
            <TabPanel key="users">
              <SettingTab
                url={usersUrl}
                selected={filteredUsers}
                tabKey="users"
                onSelect={(item) => handleChange(item, 'users')}
                searchTerm={searchTerm}
                setSearchTerm={setSearchTerm}
                ref={ref}
              />
            </TabPanel>
            {!isEnvironments && (
              <TabPanel key="teams">
                <SettingTab
                  url={teamsUrl}
                  selected={filteredTeams}
                  tabKey="teams"
                  onSelect={(item) => handleChange(item, 'teams')}
                  searchTerm={searchTerm}
                  setSearchTerm={setSearchTerm}
                  ref={ref}
                />
              </TabPanel>
            )}
          </TabPanels>
        </Tabs>
      </Combobox.Parent>
    );
  },
);

export default PopoverAccessSettings;
