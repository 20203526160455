import { useReport } from './hooks/use-context';

export function Description() {
  const { table_questions, body } = useReport();
  const flaggedQuestions = table_questions?.filter(
    (question) => question.answers?.[0]?.option === 'Não Conforme',
  );

  return (
    <div style={{ marginBottom: '10px' }}>
      <div
        style={{
          backgroundColor: '#f4a582',
          fontWeight: 'bold',
          padding: '4px',
          border: '1px solid black',
          borderTop: 'none',
          borderBottom: 'none',
          textAlign: 'center',
          fontSize: '12px',
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
        }}
      >
        TODO ITEM “NC” DEVE SER DESCRITO O DESVIO NO CAMPO ABAIXO
      </div>
      <div style={{ border: '1px solid black', minHeight: 50, padding: '4px' }}>
        {flaggedQuestions?.map((question) => {
          const globalQuestions = body?.[0]?.sections?.[0].questions;
          const globalQuestionIndex = globalQuestions.findIndex(
            (item) => item.question_id === question.question_id,
          );

          if (globalQuestions[globalQuestionIndex + 1].type === 'text')
            return (
              <p>
                {question.name.match(/^\d+/)?.[0]} -{' '}
                {globalQuestions[globalQuestionIndex + 1].answers[0].content}
              </p>
            );
        })}
      </div>
    </div>
  );
}
