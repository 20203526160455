import { useEffect, useState } from 'react';
import QRCode from 'react-qr-code';

import { OmniReportResponse, RecordType } from './types';

import './styles.css';

export function OmniReport({ report }: { report: OmniReportResponse }) {
  const [record, setRecord] = useState<RecordType>({} as RecordType);

  const templateUrl = `https://omni.wecheckstaging.com.br/external/event/${report?.header?.ticket_all_event_ids?.[1]}`;

  useEffect(() => {
    const recordData = [
      {
        checklistType: 'REC -',
        serialNumber: 'cce4f0d5-917d-49bf-bd5a-aa4b1b90d68c',
        partNumber: 'e88a0c5e-2b90-43f6-b385-a12e5def8e45',
        checklistId: '59f78029-f66d-4644-9500-0056bc674952',
        status: 'c60b1641-c356-4ff2-8197-d9d9a5431efa',
        reason: 'a1204671-b5cb-4696-bbc3-32059dead58d',
        name: '224bf32b-8593-4150-9d6e-6dcc5e35af3f',
        registration: '3307cb29-5548-44bc-afd1-d5100ebcb3da',
        sign: 'b6af8a38-f814-4b63-bc01-4fac6496aa25',
        inspection_1: 'd5aa1c0f-6eeb-4401-a319-66235bb2a98a',
        inspection_2: 'f4b645cb-48d4-4e3b-b334-64b896a9881a',
        inspection_3: 'd16ef328-8712-426d-9039-79f214bd35c5',
        inspection_4: '57380036-441c-42e3-9669-d2379ade48da',
      },
      {
        checklistType: 'PREUSO -',
        serialNumber: 'cce4f0d5-917d-49bf-bd5a-aa4b1b90d68c',
        partNumber: 'e88a0c5e-2b90-43f6-b385-a12e5def8e45',
        checklistId: '9db4f762-642f-465d-8ebb-a48fe37fe72a',
        status: '2542982c-5a19-4388-ab4e-4abf719cc247',
        reason: '8ad2b2e1-29fe-474c-b8d6-e6b46240be2e',
        name: 'c64119ea-f4c4-4de5-81d5-8fcc7de94a2f',
        registration: '5860c541-fa8f-44a0-bc4e-6c7a6267b68a',
        sign: '53a2609d-9e1d-4d4f-b769-60d45fe7e146',
        inspection_1: 'd5aa1c0f-6eeb-4401-a319-66235bb2a98a',
        inspection_2: 'f4b645cb-48d4-4e3b-b334-64b896a9881a',
        inspection_3: 'd16ef328-8712-426d-9039-79f214bd35c5',
        inspection_4: '57380036-441c-42e3-9669-d2379ade48da',
      },
      {
        checklistType: '3MO -',
        serialNumber: 'cce4f0d5-917d-49bf-bd5a-aa4b1b90d68c',
        partNumber: 'e88a0c5e-2b90-43f6-b385-a12e5def8e45',
        checklistId: '178e082b-1341-4579-adb8-c0438d095472',
        status: '160301c1-8187-4ae9-b832-f0b595980a8a',
        reason: '5a6c0b21-f1fd-43f6-95f1-86b99edcd8a3',
        name: '849bde9d-ca6a-4dd0-bc7f-07619f1955d2',
        registration: '562ef79a-3cc6-4092-8e21-d110a0769fff',
        sign: '80fbcefd-9163-412b-b86d-5ea38596e8c8',
        inspection_1: 'd5aa1c0f-6eeb-4401-a319-66235bb2a98a',
        inspection_2: 'f4b645cb-48d4-4e3b-b334-64b896a9881a',
        inspection_3: 'd16ef328-8712-426d-9039-79f214bd35c5',
        inspection_4: '57380036-441c-42e3-9669-d2379ade48da',
        next: '2b05cd59-3de5-4d88-a55e-d12e5955e1d7',
      },
      {
        checklistType: '12MO -',
        serialNumber: 'cce4f0d5-917d-49bf-bd5a-aa4b1b90d68c',
        partNumber: 'e88a0c5e-2b90-43f6-b385-a12e5def8e45',
        checklistId: 'fe242005-d5b4-4512-baf1-840a2dbd58af',
        status: 'ccda2466-49fc-4ffc-af5c-609a3b3deda2',
        reason: 'bb75c3e2-2efd-482b-b91e-271e21b2420c ',
        name: '3293e772-6bc0-4bab-8395-b9faced142ea',
        registration: '03c5ac2a-bede-4a14-b9c8-922577433398',
        sign: 'f689225e-66b4-4c7f-a8b0-30791f0b189a',
        inspection_1: 'd5aa1c0f-6eeb-4401-a319-66235bb2a98a',
        inspection_2: 'f4b645cb-48d4-4e3b-b334-64b896a9881a',
        inspection_3: 'd16ef328-8712-426d-9039-79f214bd35c5',
        inspection_4: '57380036-441c-42e3-9669-d2379ade48da',
        next: '7da736f3-f0a4-4124-8cd3-09d4a7d280d2',
      },
    ];

    const currentRecord: RecordType | undefined = recordData.find((record) =>
      report.body?.find((report) =>
        report.form_name.includes(record.checklistType),
      ),
    );

    if (currentRecord)
      Object.entries(currentRecord).forEach(([key, value]) => {
        const result = report?.body?.reduce((acc, item) => {
          item.sections?.forEach((section) => {
            const subQuestion = section.questions?.find(
              (question) => question.question_id === value,
            );

            if (subQuestion) {
              acc[key] =
                ['partNumber', 'status', 'next', 'partNumber'].includes(key) ||
                key.includes('_')
                  ? subQuestion.answers[0].option
                  : subQuestion.answers[0].content;
            }
          });
          return acc;
        }, {} as any);

        setRecord((prevRecord) => ({
          ...prevRecord,
          ...result,
        }));
      });
  }, [report]);

  return (
    <div
      className="report-container"
      style={
        report?.header?.parentable_type === 'ticket'
          ? {}
          : { pageBreakAfter: 'always' }
      }
    >
      <div className="report">
        <header className="report-header">
          <img src="logo.png" alt="Omni Logo" className="logo" />
          <div className="header-title">
            <p>F-GSE 02 - Status de Inspeção de</p>
            <p>GSE/Ferramenta</p>
            <i style={{ fontSize: 20 }}>
              F-GSE 02 - Tools/GSE Inspection Status
            </i>
          </div>
        </header>

        <h2 className="section-title">I - Identificação (Identification)</h2>
        <section>
          <div className="identification-row">
            <div
              className="label-container"
              style={{ borderRight: '5px solid #000' }}
            >
              <span>
                <p className="label">Tipo</p>
                <i className="translation">Type</i>
              </span>

              <span className="omin-value">GSE</span>
            </div>

            <div className="label-container">
              <span>
                <p className="label">Número de Série</p>
                <i className="translation">Serial Number</i>
              </span>
              <span className="omin-value">{record.serialNumber}</span>
            </div>
          </div>
          <div className="flex-row">
            <span>
              <p className="label">Descrição</p>
              <i className="translation">Description</i>
            </span>
            <span className="omin-value">TALHA ELETRICA 2.0 T</span>
          </div>
          <div className="flex-row">
            <span className="label">Part Number</span>
            <span className="omin-value">{record.partNumber}</span>
          </div>
        </section>

        <h2 className="section-title">II - Status (Status)</h2>
        <section>
          <div className="checkbox-row">
            <div className="checkbox-content">
              <input
                className="checkbox"
                type="checkbox"
                id="available"
                checked={record.status !== 'Fora de Uso'}
              />
              <span>
                <p className="label">DISPONÍVEL</p>
                <i className="translation">AVAILABLE</i>
              </span>
            </div>
            <div className="checkbox-content">
              <input
                className="checkbox"
                type="checkbox"
                id="out-of-use"
                checked={record.status === 'Fora de Uso'}
              />
              <span>
                <p className="label">FORA DE USO</p>
                <i className="translation">OUT OF USE</i>
              </span>
            </div>
          </div>
          <div className="reason-row" style={{ minHeight: '100px' }}>
            <span
              className="label"
              style={{ width: '100%', textAlign: 'center' }}
            >
              Motivo <i>(Reason)</i>:
            </span>
            <p className="reason-content">{record.reason}</p>
          </div>
        </section>

        <h2 className="section-title">
          III - Responsável pelas Informações (Data Responsible)
        </h2>
        <section>
          <div className="grid-row">
            <span className="registration-content">
              <p className="label">Matrícula - Nome</p>
              <p className="translation">
                <i>Registration - Name</i>
              </p>
              <p className="label">{record.registration}</p>
              <p className="label">{record.name}</p>
            </span>
            <span className="registration-sign">
              <span className="label">
                Rubrica <i className="translation">(Sign)</i>:
              </span>
              <div className="omni-image-container">
                <img src={record.sign} alt="sign" />
              </div>
            </span>
          </div>
        </section>

        <h2 className="section-title">
          IV - Status de Inspeção (Inspection Status)
        </h2>
        <table className="table">
          <thead className="table-header">
            <tr>
              <th>
                Data <i className="translation">(Date)</i>
              </th>
              <th>
                Inspeção <i className="translation">(Inspection)</i>
              </th>
              <th>
                Próxima <i className="translation">(Next)</i>
              </th>
            </tr>
          </thead>
          <tbody className="table-body">
            <tr>
              <td>{report.header?.event_finished_at}</td>
              <td>{record.inspection_1}</td>
              <td>{record.next}</td>
            </tr>
            <tr>
              <td />
              <td>{record.inspection_2}</td>
              <td />
            </tr>
            <tr>
              <td />
              <td>{record.inspection_3}</td>
              <td />
            </tr>
            <tr>
              <td />
              <td>{record.inspection_4}</td>
              <td />
            </tr>
          </tbody>
        </table>

        <h2 className="section-title">Acesse o checklist pelo QR Code</h2>
        <div className="flex-row">
          <div
            style={{
              margin: '5px',
              width: '100%',
              display: 'flex',
              justifyContent: 'center',
            }}
          >
            <QRCode id="qrcode" size={150} value={templateUrl} />
          </div>
        </div>
      </div>
    </div>
  );
}
