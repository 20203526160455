import constants from '@/Constants';
import { EventReportResponse } from '@/modules/pdf/event-report/types';
import { GoiasaReport } from '@/modules/pdf/goiasa';
import { GoiasaAlertReport } from '@/modules/pdf/goiasa-alert';
import { GoiasaPermitAssessment } from '@/modules/pdf/goiasa-permit-assessment';
import { GoiasaSafetyObservation } from '@/modules/pdf/goiasa-safety-observation';
import axios from 'axios';
import React, { useEffect, useState } from 'react';

export const ReportGoiasa: React.FC<{
  reportId: string;
  workspaceSlug: string;
}> = ({ reportId, workspaceSlug }) => {
  const [report, setReport] = useState<EventReportResponse | null>(null);

  useEffect(() => {
    const fetchReport = async () => {
      try {
        const url = constants.REPORTS_API_URL || '';

        const parsedUrl = url
          .toLowerCase()
          .replace('{{workspace}}', workspaceSlug);

        const { data, status } = await axios.get<EventReportResponse>(
          `${parsedUrl}pdf_json_by_export_id?export_id=${reportId}`,
          {
            headers: {
              Authorization: constants.REPORTS_API_AUTHORIZATION_TOKEN,
            },
          },
        );

        if (status === 200) {
          setReport(data);
        }
      } catch (error) {
        console.error(error);
      }
    };

    fetchReport();
  }, [reportId, workspaceSlug]);

  const getReport = () => {
    switch (report?.body?.[0]?.form_name) {
      case 'Comunicado Acidente/Incidente':
        return (
          <GoiasaAlertReport
            reportData={{
              body: report?.body,
              header: report?.header,
            }}
          />
        );

      case 'FORMU-0967 - Avaliação Permissão de Trabalho':
        return (
          <GoiasaPermitAssessment
            reportData={{
              body: report?.body,
              header: report?.header,
            }}
          />
        );

      case 'Observação de Segurança - TST':
        return (
          <GoiasaSafetyObservation
            reportData={{
              body: report?.body,
              header: report?.header,
            }}
          />
        );

      default:
        return (
          <>
            {report?.body.map((item) => (
              <GoiasaReport
                reportData={{
                  body: item,
                  header: report?.header,
                }}
              />
            ))}
          </>
        );
    }
  };

  if (!report?.body || !report?.header) return <div></div>;

  return <div style={{ height: '100%', width: '100%' }}>{getReport()}</div>;
};
