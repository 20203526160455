import { useReport } from './hooks/use-context';

export function Concern() {
  const {questions} = useReport();
  const {concern_1, concern_2, concern_3, behavior_deviation_reason} = questions;

  const containerStyle = {
    display: 'grid',
    gridTemplateColumns: '1fr',
    width: '100%',
    fontFamily: 'Arial, sans-serif',
    border: '1px solid black',
    borderBottom: 'none',
    marginBottom: '10px',
  };

  const headerStyle: any = {
    backgroundColor: '#d0e8b5',
    fontWeight: 'bold',
    padding: '6px',
    borderBottom: '1px solid black',
    fontSize: '14px',
  };

  const cellStyle: any = {
    padding: '4px',
    borderBottom: '1px solid black',
    textAlign: 'left',
    fontSize: '12px',
    minHeight: '30px',
  };

  return (
    <div style={containerStyle}>
      <div style={{...headerStyle, display: 'flex', justifyContent: 'center', alignContent: 'center'}}>PREOCUPAÇÃO</div>
      <div style={cellStyle}>{concern_1?.answers?.[0]?.content}</div>
      <div style={cellStyle}>{concern_2?.answers?.[0]?.content}</div>
      <div style={cellStyle}>{concern_3?.answers?.[0]?.content}</div>

      <div style={headerStyle}>
        Pergunte ao colaborador o porquê do desvio comportamental
      </div>
      <div style={cellStyle}>{behavior_deviation_reason?.answers?.[0]?.content}</div>
    </div>
  );
}
