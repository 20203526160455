import React, { Suspense } from 'react';
import { Route, RouteProps, Switch, useRouteMatch } from 'react-router-dom';

const Pictures = React.lazy(() => import('@/screens/Pictures/Main'));

const PicturesRoutes: React.FC<RouteProps> = () => {
  const { path } = useRouteMatch();
  return (
    <Suspense fallback={null}>
      <Switch>
        <Route path={`${path}/`} component={Pictures} />
      </Switch>
    </Suspense>
  );
};

export default PicturesRoutes;
