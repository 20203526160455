import { useReport } from './hooks/use-context';

export function Suggestions() {
  const { questions } = useReport();
  const { barrier_elimination_suggestions } = questions;

  const containerStyle = {
    display: 'grid',
    gridTemplateColumns: '1fr',
    width: '100%',
    fontFamily: 'Arial, sans-serif',
    border: '1px solid black',
    marginBottom: '10px',
  };

  const headerStyle: any = {
    backgroundColor: '#d0e8b5',
    fontWeight: 'bold',
    padding: '6px',
    borderBottom: '1px solid black',
    fontSize: '14px',
  };

  const cellStyle: any = {
    padding: '4px',
    textAlign: 'left',
    fontSize: '12px',
    minHeight: '30px',
  };

  return (
    <div style={containerStyle}>
      <div style={headerStyle}>
        Sugestões de ações para eliminar a barreira (causa do desvio)
      </div>
      <div style={cellStyle}>
        {barrier_elimination_suggestions?.answers?.[0]?.content || ''}
      </div>
    </div>
  );
}
