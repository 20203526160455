import { useReport } from './hooks/use-context';

export function InitialInfos() {
  const { questions } = useReport();
  const {
    sector,
    date,
    time,
    issuer,
    number,
    activity,
    activity_description,
    work_order_number,
    from,
    outsider_name,
  } = questions;

  const containerStyle = {
    display: 'grid',
    gridTemplateColumns: 'repeat(10, 1fr)',
    width: '100%',
    fontFamily: 'Arial, sans-serif',
    border: '1px solid black',
    borderLeft: 'none',
    borderBottom: 'none',
    marginBottom: '10px',
  };

  const cellStyle: any = {
    padding: '4px',
    border: '1px solid black',
    borderTop: 'none',
    borderRight: 'none',
    textAlign: 'left',
    fontSize: '12px',
  };

  const tableOptions = [
    'Eletricidade',
    'Espaço Confinado',
    'Içamento de Cargas',
    'Trabalho em Altura',
    'Trabalho à Quente',
    'Manutenção e/ou Intervenções Críticas',
  ];

  return (
    <div style={containerStyle}>
      <div style={{ ...cellStyle, gridColumn: 'span 6' }}>
        Setor: {sector?.answers?.[0]?.content}
      </div>
      <div style={{ ...cellStyle, gridColumn: 'span 2' }}>
        Data: {date?.answers?.[0]?.content}
      </div>
      <div style={{ ...cellStyle, gridColumn: 'span 2' }}>
        Horário: {time?.answers?.[0]?.content}
      </div>

      <div style={{ ...cellStyle, gridColumn: 'span 8' }}>
        Emissor: {issuer?.answers?.[0]?.content}
      </div>
      <div style={{ ...cellStyle, gridColumn: 'span 2' }}>
        Nº {number?.answers?.[0]?.content}
      </div>

      <div
        style={{
          ...cellStyle,
          gridRow: 'span 5',
          gridColumn: 'span 4',
          display: 'flex',
          justifyContent: 'start',
          alignItems: 'center',
        }}
      >
        Atividade:
      </div>
      <div style={{ gridColumn: 'span 6' }}>
        <div style={{ display: 'grid', gridTemplateColumns: 'repeat(9, 1fr)' }}>
          {tableOptions.map((optionName) => (
            <div
              style={{
                ...cellStyle,
                gridColumn: 'span 3',
                display: 'flex',
                justifyContent: 'start',
                alignItems: 'center',
              }}
            >
              ({activity?.answers?.[0]?.option === optionName ? 'X' : ' '})
              {` ${optionName}`}
            </div>
          ))}

          <div style={{ ...cellStyle, gridColumn: 'span 9' }}>
            <div>
              (
              {activity?.answers?.[0]?.option ===
              'Atividade recorrente de risco específico'
                ? 'X'
                : ' '}
              ) Atividade recorrente de risco específico
            </div>
            <div>Número da OS: {work_order_number?.answers?.[0]?.content}</div>
          </div>
        </div>
      </div>

      <div style={{ ...cellStyle, gridColumn: 'span 10' }}>
        Descrição da atividade: {activity_description?.answers?.[0]?.content}
      </div>

      <div style={{ ...cellStyle, gridColumn: 'span 10', display: 'flex' }}>
        <p style={{ marginRight: '10px' }}>
          ({from?.answers?.[0]?.option !== 'Terceiro' ? 'X' : ' '}) Goiasa
        </p>
        <p>
          ({from?.answers?.[0]?.option === 'Terceiro' ? 'X' : ' '}) Terceiro
          {outsider_name?.answers?.[0]?.content
            ? `(${outsider_name?.answers?.[0]?.content})`
            : ''}
        </p>
      </div>
    </div>
  );
}
