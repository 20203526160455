/* eslint-disable array-callback-return */
/* eslint-disable react/destructuring-assignment */
import { Pictures } from './pictures';
import { Description } from './description';
import { Header } from './header';
import { ReportContext } from './hooks/use-context';
import { InitialInfos } from './initial-infos';
import { Table } from './table';
import type {
  EventReportData,
  ParsedQuestions,
  QuestionsReporData,
} from './types';

export function GoiasaPermitAssessment(props: { reportData: EventReportData }) {
  const QUESTIONS_MAP: any = {
    '412cda40-56f9-4c9d-910d-09bbc9a904cf': 'sector',
    '94bffb93-4d66-45cd-8494-051352df7953': 'date',
    'de33f8c0-49f6-4fc9-8299-9e388071c910': 'time',
    'c88241a3-ce78-4e67-9baf-d64ae42ba083': 'issuer',
    '11eb5030-18cd-4f4f-9df2-51415c2b50b1': 'number',
    'c5165203-171a-42cb-8db0-016b7a58a743': 'activity',
    '125e21a3-c2ab-48a7-8083-d72e41b8defa': 'work_order_number',
    'f0eaa851-245a-43ca-82b0-cb6cdbdfd872': 'activity_description',
    '92b70efe-0a7e-4b35-a6e0-ef046f918874': 'from',
    '980df402-cb91-45f4-bcba-b78abdf84c33': 'outsider_name',
    '277af184-f7ee-4a5f-a5f2-c80e2b5dd9cf': 'evaluator',
    '18d0614c-65f1-4f26-881f-638b8a74c16e': 'issuer_signature',
  };

  const TABLE_QUESTIONS_NAMES = [
    '1. O formulário está com todos os campos preenchidos?',
    '2. Os colaboradores foram orientados quanto a atividade a ser realizada, riscos e medidas de controle?',
    '3. Existe algum risco na atividade que não foi contemplado na avaliação da  permissão de trabalho?',
    '4. Durante a avaliação, foi identificado algum comportamento de risco?',
    '5. Os colaboradores que estão relacionados na permissão possuem capacitação para atividade, consta adesivo no crachá para atividades de trabalho em altura, espaço confinado e Trabalho aéreo?',
    '6. O emissor da PET realizou o monitoramento atmosférico do espaço confinado? Foi informado aos colaboradores o resultado? Está sendo realizado o monitoramento contínuo da atmosfera durante a execução da atividade?',
    '7. Os colaboradores estão executando a atividade corretamente (Ex.: o vigia esta executando a função de vigia?)',
    '8. Caso a permissão tenha sido emitida no dia anterior, a re-inspeção foi realizada e registrada no formulário?',
  ];

  const values = props?.reportData?.body?.reduce((acc, item) => {
    item.sections.map((section) =>
      section.questions.map((question) => {
        if (question?.question_id && QUESTIONS_MAP[question?.question_id])
          acc[QUESTIONS_MAP[question?.question_id]] = question;
      }),
    );
    return acc;
  }, [] as Array<any>);

  const parsedProps: QuestionsReporData = {
    header: props?.reportData?.header,
    body: props?.reportData?.body,
    questions: (values as unknown) as ParsedQuestions,
    table_questions: props?.reportData?.body?.reduce((acc, item) => {
      item.sections.map((section) =>
        section.questions.map((question, index) => {
          if (
            question?.question_id &&
            TABLE_QUESTIONS_NAMES.includes(question?.name)
          )
            index === 0 ? (acc = [question]) : acc.push(question);
        }),
      );
      return acc;
    }, [] as Array<any>),
  };

  return (
    <ReportContext value={parsedProps}>
      <div>
        <div
          style={{
            height: '90%',
            width: '100vw',
            flex: 1,
            backgroundColor: 'white',
            fontFamily: 'sans-serif',
          }}
        >
          <div
            style={{
              margin: '1rem auto',
              height: 'auto',
              maxWidth: '64rem',
            }}
          >
            <Header formName="Avaliação Permissão de Trabalho" />
            <div
              style={{
                height: '100%',
                width: '100%',
                borderWidth: '1px',
                borderStyle: 'solid',
                borderColor: '#000',
                backgroundColor: '#fff',
                padding: '1rem',
              }}
            >
              <InitialInfos />
              <Table />
              <Description />
              <Pictures />
            </div>
          </div>
        </div>
      </div>
    </ReportContext>
  );
}
