import { createContext } from '@/utils/react-utils';
import {
  Box,
  Button,
  ButtonProps,
  chakra,
  ComponentWithAs,
  Flex,
  HStack,
  Input,
  InputProps,
  List,
  ListItem,
  ListItemProps,
  PlacementWithLogical,
  Popover,
  PopoverBody,
  PopoverContent,
  PopoverFooter,
  PopoverTrigger,
  Portal,
  Text,
  useColorModeValue,
} from '@chakra-ui/react';
import React from 'react';

import { CustomIcons } from '../../screens/Workflow/utils/customIcons';

const [ComboboxProvider, useComboboxContext] = createContext<{
  onClose: () => void;
}>({
  name: 'ComboboxContext',
});

const ComboboxTrigger = React.forwardRef<HTMLButtonElement, ButtonProps>(
  ({ children, ...rest }, ref) => {
    return (
      <Button
        flex={0}
        ref={ref}
        rounded="md"
        bg="white"
        variant="outline"
        {...rest}
        display="flex"
        flexDir="row"
        alignItems="center"
        justifyContent="space-between"
        color="gray.400"
        overflow="clip"
        position="relative"
      >
        <Text
          textOverflow="ellipsis"
          wordBreak="break-all"
          overflow="hidden"
          textAlign="start"
        >
          {children}
        </Text>

        <Box w="4" display="flex">
          <CustomIcons.ChevronDownIcon fontSize="lg" />
        </Box>
      </Button>
    );
  },
);

export type ComboboxListItemProps = ListItemProps & {
  leftContent?: React.ReactNode;
  rightContent?: React.ReactNode;
};

export type ComboboxListItemType = ComponentWithAs<'li', ComboboxListItemProps>;

const ComboboxListItem: ComboboxListItemType = ({
  children,
  rightContent,
  leftContent,
  ...rest
}) => {
  const buttonStyles: ButtonProps = React.useMemo(
    () => ({
      textDecoration: 'none',
      color: 'inherit',
      userSelect: 'none',
      display: 'flex',
      width: '100%',
      alignItems: 'center',
      textAlign: 'start',
      flex: '0 0 auto',
      outline: 0,
      py: '0.4rem',
      w: 'full',
      px: '0.8rem',
      transitionProperty: 'background',
      transitionDuration: 'ultra-fast',
      transitionTimingFunction: 'ease-in',
      rounded: 'lg',
    }),
    [],
  );

  return (
    <ListItem {...rest}>
      <chakra.button
        _hover={{
          bg: useColorModeValue('gray.100', 'whiteAlpha.100'),
        }}
        _disabled={{
          opacity: 0.4,
          cursor: 'not-allowed',
        }}
        {...buttonStyles}
      >
        <Flex justify="space-between" w="full" alignItems="center">
          <HStack>
            {!!leftContent && leftContent}
            <Text noOfLines={1} wordBreak="break-all">
              {children}
            </Text>
          </HStack>
          {!!rightContent && rightContent}
        </Flex>
      </chakra.button>
    </ListItem>
  );
};

type ListSectionType = React.FC<{ label: string }>;

const ListSection: ListSectionType = ({ label, children }) => {
  return (
    <Flex flexDir="column" w="full">
      <Text fontWeight="semibold" fontSize="sm" color="muted" mb="2">
        {label}
      </Text>
      <List>{children}</List>
    </Flex>
  );
};

const ComboboxSearchInput = (props: any) => {
  const handleClick = () => {
    document.getElementById(props.tabKey)?.focus();
  };

  return (
    <Input
      {...props}
      id={props.tabKey}
      h="8"
      mt="2"
      mb="4"
      rounded="md"
      bg="white"
      placeholder="Nome"
      onClick={handleClick}
      autoFocus
    />
  );
};

export type ComboboxParentProps = {
  renderFooter?: (onClose: () => void) => React.ReactNode;
  TriggerComponent?: React.ReactNode;
  triggerPlaceholder?: React.ReactNode;
  selectedContent?: React.ReactNode;
  triggerProps?: ButtonProps;
  initialFocusRef?: React.RefObject<HTMLInputElement>;
  onOpen?: (() => void) | undefined;
  onClose?: (() => void) | undefined;
  containerRef?: React.RefObject<HTMLElement | null> | undefined;
  placement?: PlacementWithLogical;
};

const ComboboxParent: React.FC<ComboboxParentProps & { isOpen?: boolean }> = ({
  children,
  renderFooter = () => null,
  TriggerComponent,
  triggerPlaceholder = 'Selecione...',
  selectedContent,
  triggerProps,
  onOpen,
  onClose: onCloseProps,
  isOpen,
  placement = 'bottom-start',
}) => {
  const renderTrigger = React.useCallback(() => {
    if (TriggerComponent) {
      return TriggerComponent;
    }
    return (
      <ComboboxTrigger
        rounded="md"
        bg="white"
        variant="outline"
        {...triggerProps}
      >
        {selectedContent || triggerPlaceholder}
      </ComboboxTrigger>
    );
  }, [TriggerComponent, selectedContent, triggerPlaceholder, triggerProps]);

  return (
    <Popover
      isOpen={isOpen}
      defaultIsOpen={false}
      onClose={onCloseProps}
      onOpen={onOpen}
      isLazy
      matchWidth
      placement={placement}
    >
      {({ onClose }) => (
        <>
          <PopoverTrigger>{renderTrigger()}</PopoverTrigger>

          <Portal>
            <PopoverContent
              w="97vw"
              maxW={400}
              marginRight={2}
              rounded="lg"
              _focus={{ outline: 'none', boxShadow: 'none' }}
            >
              <PopoverBody
                maxH="300px"
                minH="150px"
                overflowX="hidden"
                scrollBehavior="smooth"
                overflowY="auto"
                px="0"
                roundedTop="lg"
                boxShadow="comboboxShadow"
              >
                <Flex flexDir="column" w="full" px="3">
                  <ComboboxProvider value={{ onClose }}>
                    {children}
                  </ComboboxProvider>
                </Flex>
              </PopoverBody>
              <PopoverFooter
                boxShadow="comboboxShadow"
                roundedBottom="lg"
                bg="white"
              >
                {renderFooter(onClose)}
              </PopoverFooter>
            </PopoverContent>
          </Portal>
        </>
      )}
    </Popover>
  );
};

export const Combobox = {
  Parent: ComboboxParent,
  Trigger: ComboboxTrigger,
  Search: ComboboxSearchInput,
  ListSection,
  ListItem: ComboboxListItem,
};

export { useComboboxContext };
