import Tooltip from '@/components/Tooltip';
import ToggleButton from '@/components_v2/Buttons/Toggle';
import { Combobox } from '@/components_v2/Combobox/Combobox';
import { Div } from '@/components_v2/inputs/BaseInput/styles';
import { useSurveyForm } from '@/modules/SurveyForm';
import {
  Accordion,
  AccordionButton,
  AccordionItem,
  AccordionPanel,
  Box,
  Button,
  Center,
  Checkbox,
  List,
  ListItem,
  Spinner,
} from '@chakra-ui/react';
import React, { useState } from 'react';

import { QuestionProps } from '../../interfaces';
import {
  InfoTooltip,
  InputDiv,
  QuestionContainer,
  QuestionHeader,
  QuestionSubtitle,
  QuestionTitle,
  TooltipContainer,
} from '../common/styles';
import { ToggleContainer } from '../Toggle/styles';
import {
  FormularyGroup,
  FormularySelectProvider,
  useFormularySelect,
  useFormularySelectContext,
} from './use-formulary-select';

type FormularySelectProps = QuestionProps;

function Group(props: { formularyGroup: FormularyGroup }) {
  const { formularyGroup } = props;
  const { selected, toggleItem, toggleMultiple } = useFormularySelectContext();

  const ids = React.useMemo(() => formularyGroup.formularies.map((e) => e.id), [
    formularyGroup.formularies,
  ]);

  const allChecked = React.useMemo(
    () => !!ids.length && ids.every((e) => selected.includes(e)),
    [ids, selected],
  );

  const isIndeterminate = React.useMemo(
    () => ids.some((e) => selected.includes(e)) && !allChecked,
    [allChecked, ids, selected],
  );

  return (
    <AccordionItem border="none">
      <h2>
        <AccordionButton>
          <Box flex="1" textAlign="left">
            <Checkbox
              isChecked={allChecked}
              isIndeterminate={isIndeterminate}
              onChange={() =>
                toggleMultiple(formularyGroup.id, isIndeterminate)
              }
            >
              {formularyGroup.name} {`(${formularyGroup.formularies.length})`}
            </Checkbox>
          </Box>
        </AccordionButton>
      </h2>
      <AccordionPanel ms="6" pb={4}>
        <List spacing={3}>
          {formularyGroup.formularies.map((formulary) => (
            <ListItem key={formulary.id}>
              <Checkbox
                isChecked={selected.includes(formulary.id)}
                onChange={() => toggleItem(formulary.id)}
                size="md"
              >
                {formulary.name}
              </Checkbox>
            </ListItem>
          ))}
        </List>
      </AccordionPanel>
    </AccordionItem>
  );
}

export function FormularySelect({
  onChange,
  initialValues,
  errors,
  question,
  onChangeScheduleTitle,
}: FormularySelectProps) {
  const initialized = React.useRef(false);
  const [onInheritName, setOnInheritName] = useState(false);
  const parsedInitialValues = React.useMemo(() => {
    if (!initialValues?.values || !Array.isArray(initialValues?.values))
      return [];

    return initialValues?.values.map((e: { id: string; name: string }) => e.id);
  }, [initialValues?.values]);

  const { setInheritedTitle } = useSurveyForm();

  const hook = useFormularySelect(parsedInitialValues);
  const { loading, sectionedData, selected, selectedString, setSearch } = hook;

  React.useEffect(() => {
    if (!initialized.current) {
      initialized.current = true;
      return;
    }

    onChange(selected.map((id) => ({ id, name: '' })));
    onChangeScheduleTitle(onInheritName ? selectedString?.split(',')[0] : '');
  }, [
    onChangeScheduleTitle,
    onChange,
    selected,
    selectedString,
    onInheritName,
  ]);

  return (
    <QuestionContainer>
      <QuestionHeader>
        <QuestionTitle>{question.name}</QuestionTitle>
        {question.tipText && (
          <Tooltip description={question.tipText} placement="top right">
            <TooltipContainer>
              <InfoTooltip />
            </TooltipContainer>
          </Tooltip>
        )}
      </QuestionHeader>
      <FormularySelectProvider value={hook}>
        <Combobox.Parent
          placement='auto'
          TriggerComponent={
            <InputDiv>
              <Div>{selectedString || 'Selecione os formulários'}</Div>
            </InputDiv>
          }
          renderFooter={(onClose) => (
            <Box>
              <Button float="right" colorScheme="brand" onClick={onClose}>
                Confirmar
              </Button>
            </Box>
          )}
        >
          <Box w="full">
            {loading && !sectionedData.length && (
              <Center>
                <Spinner size="lg" />
              </Center>
            )}
            <Accordion
              allowToggle={false}
              index={sectionedData.map((_, index) => index)}
              w="full"
              allowMultiple
            >
              <Combobox.Search onChange={(e) => setSearch(e.target.value)} />
              {sectionedData.map((formularyGroup) => (
                <Group
                  key={formularyGroup.id}
                  formularyGroup={formularyGroup}
                />
              ))}
            </Accordion>
          </Box>
        </Combobox.Parent>
      </FormularySelectProvider>

      {question.inherited_template_name && (
        <ToggleContainer style={{ marginTop: 10 }}>
          <QuestionTitle>Herdar nome do checklist</QuestionTitle>
          <ToggleButton
            state={onInheritName}
            onChange={() => {
              setOnInheritName(!onInheritName);
              setInheritedTitle(!onInheritName);
            }}
          />
        </ToggleContainer>
      )}

      <QuestionSubtitle hasError={!!errors}>
        {errors && errors.errors}
      </QuestionSubtitle>
    </QuestionContainer>
  );
}
