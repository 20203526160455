import { ExportParentableTypes, ExportStatus } from '@/gql/graphql';
import {
  Button,
  Collapse,
  Divider,
  Flex,
  HStack,
  Progress,
  Tag,
  Text,
  VStack,
} from '@chakra-ui/react';
import moment from 'moment';
import React from 'react';

import { useExportItem } from '../hooks/useExportItem';
import { FileTransferItem, KVItem, KVTable } from './FileTransferItem';

function Body() {
  const {
    exportItem: { exportType, titleEvent, author },
  } = useExportItem();

  return (
    <KVTable>
      <KVItem label="Tipo de Arquivo" value={`.${exportType}`} />
      <KVItem label="Requisitado por:" value={author?.fullName || ''} />
      {!!titleEvent && <KVItem label="Evento:" value={titleEvent || ''} />}
    </KVTable>
  );
}

function Footer() {
  const [collapsed, setCollapsed] = React.useState(true);
  const {
    exportItem: {
      progress,
      status,
      url,
      pivoted,
      exportErrors,
      exportColumns,
      dateEnd,
      dateStart,
    },
  } = useExportItem();

  return (
    <VStack w="full">
      <HStack w="full" px="2" py="1">
        <Flex gap="2" flex={1}>
          <Button
            disabled={status !== ExportStatus.Done}
            colorScheme={status === ExportStatus.Done ? 'primary' : 'gray'}
            onClick={() => {
              window.open(`${url}`);
            }}
            size="sm"
            variant="outline"
          >
            Baixar
          </Button>
          <Button
            onClick={() => setCollapsed((old) => !old)}
            size="sm"
            variant="outline"
          >
            Detalhes
          </Button>
        </Flex>
        <Flex flex="0.5">
          {[ExportStatus.Processing, ExportStatus.Pending].includes(
            status!,
          ) && (
            <Progress
              borderWidth="thin"
              w="full"
              size="lg"
              rounded="md"
              value={progress || 0}
              isIndeterminate={status === ExportStatus.Pending}
            />
          )}
        </Flex>
      </HStack>
      <Collapse style={{ width: '100%' }} in={!collapsed}>
        <VStack
          alignItems="flex-start"
          w="full"
          p="2"
          bg="white"
          rounded="lg"
          borderWidth="thin"
        >
          <KVTable>
            {!!exportErrors?.length && (
              <KVItem
                label="Erros"
                value={
                  <>
                    {exportErrors?.map((err) => (
                      <Text fontWeight="bold" color="red" key={err} as="span">
                        {err}
                      </Text>
                    ))}
                  </>
                }
              />
            )}
            <KVItem label="Pivotada" value={pivoted ? 'Sim' : 'Não'} />
            {!!dateStart && (
              <KVItem
                label="Data de início"
                value={`${moment(dateStart).format('L')}`}
              />
            )}
            {!!dateEnd && (
              <KVItem
                label="Data de fim"
                value={`${moment(dateEnd).format('L')}`}
              />
            )}
            {!!exportColumns?.length && (
              <KVItem label="Colunas" value={exportColumns?.join(', ') || ''} />
            )}
          </KVTable>
        </VStack>
      </Collapse>
    </VStack>
  );
}

export function ExportItem() {
  //
  const { exportItem } = useExportItem();

  const { parentableType, createdAt, status } = exportItem;

  const title = React.useMemo(() => {
    switch (parentableType) {
      case ExportParentableTypes.Collections:
        return 'Exportação de Coleções';
      case ExportParentableTypes.Dashboard:
        return 'Exportação de Dashboard';
      case ExportParentableTypes.Events:
        return 'Exportação de Evento';
      case ExportParentableTypes.Formularies:
        return 'Exportação de Formulários';
      case ExportParentableTypes.Locals:
        return 'Exportação de Locais';
      case ExportParentableTypes.Schedules:
        return 'Exportação de Agendamentos';
      case ExportParentableTypes.TaskAnswers:
        return 'Exportação de Respostas de Tarefas';
      case ExportParentableTypes.Tasks:
        return 'Exportação de Respostas de Tarefas';
      case ExportParentableTypes.Tickets:
        return 'Exportação de Respostas de Tickets';
      case ExportParentableTypes.Users:
        return 'Exportação de Respostas de Usuários';
      case ExportParentableTypes.PhotoBook:
        return 'Exportação de Galeria de Fotos';

      default:
        return '';
    }
  }, [parentableType]);

  const parsedDate = moment(createdAt).format('LLLL');

  return (
    <FileTransferItem
      title={title}
      subtitle={parsedDate}
      status={status || 'pending'}
      body={<Body />}
      footer={<Footer />}
    />
  );
}
