import constants, { DOMAIN_PLACEHOLDER } from '@/Constants';
import { getSubdomain } from '@/utils/getSubdomain';
import {
  HttpParamsConfig,
  HttpResponse,
  IApiHelper,
} from '@shared/data/protocols/http';
import axios, { AxiosInstance, AxiosResponse, CancelToken } from 'axios';

export type getParams = {
  url: string;
  timeout?: number;
  apiV?: number;
  cancelToken?: CancelToken;
};

export type postParams = {
  url: string;
  timeout?: number;
  body?: any;
  data?: any;
  apiV?: number;
};

const apiConfig = {
  baseUrl: constants.BASE_URL,
  validateStatus: () => {
    return true;
  },
};

class AxiosHelper implements IApiHelper {
  token: string | null;
  baseUrl: string;
  public instance: AxiosInstance;
  private urlWithDomain: string;

  constructor(con: typeof apiConfig) {
    this.baseUrl = con.baseUrl;
    this.token = null;
    this.instance = axios.create();
    this.setDomain(getSubdomain());

    this.getFullPath.bind(this);
    this.setToken.bind(this);
    this.setDomain.bind(this);
  }

  public getFullPath(): string {
    return this.urlWithDomain;
  }

  private prepareConfig(): HttpParamsConfig {
    const header = this.token ? { Authorization: this.token } : undefined;
    const config = {
      headers: header,
      timeout: 50000,
      validateStatus: () => {
        return true;
      },
    };

    this.instance.defaults = {
      ...this.instance.defaults,
      ...config,
    };
    return config;
  }

  public setToken(token: string): void {
    this.token = token;
    this.prepareConfig();
  }

  public setDomain(domain: string) {
    const currentUrl: string = this.baseUrl.replace(DOMAIN_PLACEHOLDER, domain);
    this.urlWithDomain = currentUrl;
    this.prepareConfig();
  }

  public clearToken(): void {
    this.token = null;
    this.prepareConfig();
  }

  public createInterceptor(
    onResponse?: (response: AxiosResponse<any>) => AxiosResponse<any>,
    onReject?: (error: any) => any,
  ): number {
    const id = this.instance.interceptors.response.use(onResponse, onReject);
    return id;
  }

  public removeInterceptor(id: number) {
    this.instance.interceptors.response.eject(id);
  }

  private getUrl(paramUrl: string, apiV: number = 1): string {
    let url = '';
    if (paramUrl.includes('http')) {
      url = paramUrl;
    } else if (paramUrl.includes('api/v')) {
      url = `${this.urlWithDomain.slice(
        0,
        this.urlWithDomain.length - 4,
      )}${paramUrl}`;
    } else {
      url = `${this.urlWithDomain}v${apiV}/${paramUrl}`;
    }
    return url;
  }

  public async get<T = any>(params: getParams): Promise<HttpResponse<T>> {
    const url = this.getUrl(params.url, params.apiV);

    const configs = {
      ...this.prepareConfig(),
      ...(params.cancelToken && { cancelToken: params.cancelToken }),
    };

    const response = await this.instance.get(url, configs);

    return {
      statusCode: response.status,
      body: response.data,
    };
  }

  public async post<T = any>(
    params: postParams,
  ): Promise<HttpResponse<T | undefined>> {
    const url = this.getUrl(params.url, params.apiV);

    try {
      const response = await this.instance.post(
        url,
        params.body,
        this.prepareConfig(),
      );

      return {
        statusCode: response.status,
        body: response.data,
      };
    } catch (err) {
      return {
        statusCode: 404,
        body: undefined,
      };
    }
  }

  public async patch(params: postParams): Promise<HttpResponse<any>> {
    const url = this.getUrl(params.url, params.apiV);

    const response = await this.instance.patch(
      url,
      params.body,
      this.prepareConfig(),
    );

    return {
      statusCode: response.status,
      body: response.data,
    };
  }

  public async put(params: postParams): Promise<HttpResponse<any>> {
    const url = this.getUrl(params.url, params.apiV);

    const response = await this.instance.put(
      url,
      params.body,
      this.prepareConfig(),
    );

    return {
      statusCode: response.status,
      body: response.data,
    };
  }

  public async delete(params: postParams): Promise<HttpResponse<any>> {
    const url = this.getUrl(params.url, params.apiV);

    const response = await this.instance.delete(url, {
      data: params.body,
      ...this.prepareConfig(),
    });

    return {
      statusCode: response.status,
      body: response.data,
    };
  }
}

const api = new AxiosHelper(apiConfig);

export default api;
