/* eslint-disable array-callback-return */
/* eslint-disable consistent-return */

import { QuestionModel } from '@/modules/core/domain/interfaces/IFormulary';
import useField from '@/modules/WebForm/hooks/useField';
import { useSyncState } from '@/modules/WebForm/hooks/useSyncState';
import { createAnswer } from '@/modules/WebForm/utils';
import { Badge, Flex } from '@chakra-ui/react';
import { MultiValue, Select } from 'chakra-react-select';
import React from 'react';

import FormControlComponent from '../FormControlComponent';

const CheckboxComboContent: React.FC<QuestionModel> = ({ options }) => {
  const { error, onChange, fieldState, clear } = useField();
  const { forceSync } = useSyncState();

  const value = React.useMemo<SelectOption[] | []>(() => {
    if (fieldState) {
      if (Array.isArray(fieldState)) {
        return options
          .filter((o) => fieldState.find((v) => v.content === o.id))
          .map((o) => ({
            label: o.name,
            value: o.id,
            message: o.alertMessage,
          }));
      }

      const option = options.find((o) => o.id === fieldState.content);
      return option
        ? [
            {
              label: option.name,
              value: option.id,
              message: option.alertMessage,
            },
          ]
        : [];
    }

    return [];
  }, [fieldState, options]);

  const handleChange = React.useCallback(
    (newValue: MultiValue<SelectOption>) => {
      if (newValue.length === 0) {
        clear();
      } else {
        onChange([...newValue.map((v) => createAnswer(v.value))]);
      }

      forceSync();
    },
    [clear, forceSync, onChange],
  );

  return (
    <Flex w="full" flexDir="column">
      <FormControlComponent isInvalid={error}>
        <Select
          // menuPortalTarget={document.body}
          styles={{
            menuPortal: (provided) => ({ ...provided, zIndex: 1600 }),
          }}
          chakraStyles={{
            container: (provided) => ({
              ...provided,
              flex: 1,
              maxWidth: '300px',
            }),
          }}
          onChange={handleChange}
          isMulti
          options={options.map((o) => ({
            label: o.name,
            value: o.id,
            message: o.alertMessage,
          }))}
          value={value}
        />
      </FormControlComponent>
      {value.map(
        (v) =>
          v.message && (
            <Badge
              rounded="md"
              w="min-content"
              fontSize="md"
              fontWeight="bold"
              colorScheme="orange"
              variant="outline"
              marginTop={4}
              translate="no"
            >
              {v.label} - {v.message}
            </Badge>
          ),
      )}
    </Flex>
  );
};

export default CheckboxComboContent;
