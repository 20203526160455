import { useReport } from './hooks/use-context';

export function RiskTable() {
  const { questions } = useReport();
  const {
    hazard,
    frequency,
    scope,
    probability,
    severity,
    risk_level,
  } = questions;

  const containerStyle = {
    display: 'grid',
    gridTemplateColumns: '1fr',
    width: '100%',
    fontFamily: 'Arial, sans-serif',
    border: '1px solid black',
    borderBottom: 'none',
    borderRight: 'none',
    marginBottom: '10px',
  };

  const headerStyle: any = {
    backgroundColor: '#ffffff',
    fontWeight: 'bold',
    padding: '6px',
    borderBottom: '1px solid black',
    fontSize: '14px',
    textAlign: 'center',
  };

  const cellStyle: any = {
    padding: '8px',
    borderBottom: '1px solid black',
    borderRight: '1px solid black',
    textAlign: 'center',
    fontSize: '14px',
  };

  const yellowCellStyle = {
    ...cellStyle,
    backgroundColor: '#FFD700',
    fontWeight: 'bold',
  };

  return (
    <div style={containerStyle}>
      <div style={{ ...headerStyle, gridColumn: 'span 6' }}>
        Matriz de Risco
      </div>

      <div style={yellowCellStyle}>Perigo</div>
      <div style={cellStyle}>Frequência</div>
      <div style={cellStyle}>Abrangência</div>
      <div style={cellStyle}>Probabilidade</div>
      <div style={cellStyle}>Severidade</div>
      <div style={yellowCellStyle}>Nível do Risco</div>

      <div style={yellowCellStyle}>{hazard?.answers?.[0]?.content || ''}</div>
      <div style={cellStyle}>{frequency?.answers?.[0]?.content || ''}</div>
      <div style={cellStyle}>{scope?.answers?.[0]?.content || ''}</div>
      <div style={cellStyle}>{probability?.answers?.[0]?.content || ''}</div>
      <div style={cellStyle}>{severity?.answers?.[0]?.content || ''}</div>
      <div style={yellowCellStyle}>
        {risk_level?.answers?.[0]?.content || ''}
      </div>
    </div>
  );
}
